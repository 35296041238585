import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, catchError, withLatestFrom, filter, exhaustMap, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { saveAs } from 'file-saver';

import * as datalakeActions from './datalake.action';
import { DatalakeService } from './../../core/services/datalake';
import { ApplicationState } from 'src/app/app.state';
import { profileLake, userDirectory, userDocuments } from './datalake.state';

@Injectable()
export class DatalakeEffects {
  constructor(private actions$: Actions, private datalakeService: DatalakeService, public store: Store<ApplicationState>) { }

  @Effect()
  public loadProfileLakeDetailsEffect = this.actions$.pipe(
    ofType(datalakeActions.DatalakeActions.LOAD_PROFILE_LAKE_DETAILS),
    withLatestFrom(this.store.pipe(select(profileLake))),
    filter(([_, profileLake]) => {
      if (profileLake) { return false } return true
    }),
    exhaustMap(() => this.datalakeService.getProfileLake().pipe(
      map(response => (new datalakeActions.LoadProfileLakeDetailsSuccess(response))),
      catchError((error) => of(new datalakeActions.LoadProfileLakeDetailsFailed(error)))
    ))
  );

  @Effect()
  public loadUserDirectoryByLakeEffect = this.actions$.pipe(
    ofType(datalakeActions.DatalakeActions.LOAD_USER_DIRECTORY_BY_LAKE),
    withLatestFrom(this.store.pipe(select(userDirectory))),
    filter(([_, userDirectory]) => {
      if (userDirectory) { return false } return true
    }),
    map((action: any) => action[0]),
    exhaustMap((action: datalakeActions.LoadUserDirectoryByLake) =>
      this.datalakeService.getUserDirectoryByLake(action.payload).pipe(
        map(response => (new datalakeActions.LoadUserDirectoryByLakeSuccess(response))),
        catchError((error) => of(new datalakeActions.LoadUserDirectoryByLakeFailed(error)))
      )
    )
  );

  @Effect()
  public loadUserDocumentsByLakeAndUserDirectoryEffect = this.actions$.pipe(
    ofType(datalakeActions.DatalakeActions.LOAD_USER_DOCUMENTS_BY_LAKE_AND_USER_DIRECTORY),
    withLatestFrom(this.store.pipe(select(userDocuments))),
    filter(([_, userDocuments]) => {
      if (userDocuments) { return false } return true
    }),
    map((action: any) => action[0]),
    exhaustMap((action: datalakeActions.LoadUserDocumentsByLakeAndUserDirectory) =>
      this.datalakeService.getUserDocuments(action.payload.lakeId, action.payload.parentId).pipe(
        map(response => (new datalakeActions.LoadUserDocumentsByLakeAndUserDirectorySuccess(response))),
        catchError((error) => of(new datalakeActions.LoadUserDocumentsByLakeAndUserDirectoryFailed(error)))
      )
    )
  );

  @Effect()
  public loadUploadDocumentsEffect$ = this.actions$.pipe(
    ofType(datalakeActions.DatalakeActions.UPLOAD_DOCUMENTS),
    exhaustMap((action: datalakeActions.UploadDocuments) => {
      const actions = [];
      Array.from(action.payload.drops).forEach(document => {
        const payload = JSON.parse(JSON.stringify(action.payload));
        payload.drop = document;
        delete payload.drops;
        payload.file_name = payload.drop.name;
        payload.name = payload.drop.name;
        payload.size = payload.drop.size;
        payload.kind = payload.drop.type;
        actions.push(new datalakeActions.UploadDocument(payload));
      });
      return actions;
    })
  );

  @Effect()
  public loadUploadDocumentEffect$ = this.actions$.pipe(
    ofType(datalakeActions.DatalakeActions.UPLOAD_DOCUMENT),
    flatMap((action: datalakeActions.UploadDocument) => {
      return this.datalakeService.uploadDocument(action.payload).pipe(
        map(response => {
          const document = action.payload;
          document.id = response.drop_id;
          return new datalakeActions.UploadDocumentSuccess(document)
        }),
        catchError((error) => of(new datalakeActions.UploadDocumentFailed(error)))
      );
    })
  );

  @Effect()
  public loadDownloadDocumentEffect$ = this.actions$.pipe(
    ofType(datalakeActions.DatalakeActions.DOWNLOAD_DOCUMENT),
    flatMap((action: datalakeActions.DownloadDocument) => {
      return this.datalakeService.downloadFile(action.payload.document.id, action.payload.query).pipe(
        map(response => new datalakeActions.DownloadDocumentSuccess({ arrayBuffer: response, data: action.payload })),
        catchError((error) => of(new datalakeActions.DownloadDocumentFailed(error)))
      );
    })
  );

  @Effect({ dispatch: false })
  public loadDownloadDocumentSuccessEffect$ = this.actions$.pipe(
    ofType(datalakeActions.DatalakeActions.DOWNLOAD_DOCUMENT_SUCCESS),
    map((action: datalakeActions.DownloadDocumentSuccess) => {
      // parse the bufferd response into blob and save it with the drop.name
      const blob = new Blob([action.payload.arrayBuffer], { type: action.payload.data.document.kind });
      saveAs(blob, action.payload.data.document.name);
    })
  );

  @Effect()
  public removeDocumentEffect = this.actions$.pipe(
    ofType(datalakeActions.DatalakeActions.REMOVE_DOCUMENT),
    exhaustMap((action: datalakeActions.RemoveDocument) =>
      this.datalakeService.removeDocument(action.payload.document, action.payload.query).pipe(
        map(response => (new datalakeActions.RemoveDocumentSuccess(action.payload.document.drop_id))),
        catchError((error) => of(new datalakeActions.RemoveDocumentFailed(error)))
      )
    )
  );

  @Effect()
  public loadLastDBBackupEffect = this.actions$.pipe(
    ofType(datalakeActions.DatalakeActions.LOAD_LAST_DB_BACKUP_DATE),
    exhaustMap((action: datalakeActions.LoadLastDBBackup) =>
      this.datalakeService.getLastDayOfBackup().pipe(
        map(response => (new datalakeActions.LoadLastDBBackupSuccess(response))),
        catchError((error) => of(new datalakeActions.LoadLastDBBackupFailed(error)))
      )
    )
  );

}
