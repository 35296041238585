import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';

export interface FooterState {
    copyright: any;
}

export const initialState: FooterState = {
    copyright: []
};

export const getApplicationState = (state: ApplicationState) => state;

// exports  footer from ApplicationState
export const  FooterState = createSelector(getApplicationState, (state: ApplicationState) => state.footer);

export const copyright = createSelector( FooterState, state => state.copyright);