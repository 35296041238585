import * as userActions from "./user.action";
import { IUserPreference, IGroup } from "src/app/core/models/user";

export function userReducer(state: any, action: userActions.UserAction): any {
  switch (action.type) {
    case userActions.LoginActions.LOGOUT: {
      return {
        ...state,
        isAuthenticated: false
      };
    }
    case userActions.LoginActions.LOGOUT_SUCCESS: {
      return {
        ...state,
        profile: null,
        groups: null,
        passwordPolicy: null,
        userPreferences: null,
        isAuthenticated: false,
        authenticatedUser: null,
        error: null
      };
    }
    case userActions.LoginActions.LOGIN: {
      return {
        ...state,
        isAuthenticating: true
      };
    }
    case userActions.LoginActions.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        authenticatedUser: action.payload,
        error: null
      };
    }
    case userActions.LoginActions.LOGIN_FAILED: {
      return {
        ...state,
        isAuthenticated: false,
        authenticatedUser: null,
        isAuthenticating: false,
        error: action.payload,
        message: null
      };
    }
    case userActions.LoginActions.AUTHENTICATION_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        authenticatedUser: JSON.parse(action.payload),
        error: null
      };
    }

    case userActions.LoginActions.RECOVER_PASSWORD_SUCCESS: {
      return {
        ...state,
        message: {

          message: "We've sent new password to your registered email address."
        },
        error: null
      };
    }
    case userActions.LoginActions.RECOVER_PASSWORD_FAILED: {
      return {
        ...state,
        message: {

          message: null
        },
        error: "No User Exists!!"
      };
    }
    case userActions.LoginActions.UPDATE_UNBLOCK_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        message: {
          message: "We have set new Password,please check your email!"
        },
        error: null
      };
    }
    case userActions.LoginActions.UPDATE_UNBLOCK_USER_PASSWORD_FAILED: {
      return {
        ...state,
        message: {
          message: null
        },
        error: "seem some issue"
      };
    }

    case userActions.LoginActions.AUTHENTICATION_FAIL: {
      return {
        ...state,
        authenticatedUser: null,
        error: null
      };
    }
    case userActions.UserActions.LOAD_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.payload
      };
    }
    case userActions.UserActions.LOAD_USER_PROFILE_FAILED: {
      return {
        ...state,
        profile: null,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_PASSWORD_POLICY_SUCCESS: {
      return {
        ...state,
        passwordPolicy: action.payload
      };
    }
    case userActions.UserActions.LOAD_PASSWORD_POLICY_FAILED: {
      return {
        ...state,
        error: action.payload
      };
    }
    case userActions.UserActions.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        message: { success: action.payload.message }
      };
    }
    case userActions.UserActions.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        message: { error: "Current password entered is wrong!" }
      };
    }
    case userActions.UserActions.UPDATE_USER: {
      return {
        ...state,
        isUserProcessing: true,
        message: null,
        error: null
      };
    }
    case userActions.UserActions.UPDATE_USER_SUCCESS: {
      if (state.profile) {
        state.profile.name = action.payload.name;
      }
      return {
        ...state,
        isUserProcessing: false,
        message: { success: "User updated Successfully" }
      };
    }
    case userActions.UserActions.UPDATE_USER_FAILED: {
      return {
        ...state,
        isUserProcessing: false,
        error: action.payload
      };
    }
    case userActions.UserActions.UPDATE_USER_DETAILS: {
      return {
        ...state,
        isDetailsProcessing: true
      };
    }
    case userActions.UserActions.UPDATE_USER_DETAILS_SUCCESS: {
      if (state.profile) {
        state.profile.id = action.payload.id;
        state.profile.designation = action.payload.designation;
        state.profile.phone_office = action.payload.phone_office;
        state.profile.phone_residence = action.payload.phone_residence;
        state.profile.phone_mobile = action.payload.phone_mobile;
        state.profile.email_id_business = action.payload.email_id_business;
        state.profile.email_id_personal = action.payload.email_id_personal;
        state.profile.primary_address = action.payload.primary_address;
        state.profile.secondary_address = action.payload.secondary_address;
      }
      return {
        ...state,
        isDetailsProcessing: false,
        message: { success: "User updated Successfully" }
      };
    }
    case userActions.UserActions.UPDATE_USER_DETAILS_FAILED: {
      return {
        ...state,
        isDetailsProcessing: false,
        error: action.payload
      };
    }
    case userActions.UserActions.SET_MESSAGE: {
      return {
        ...state,
        message: action.payload
      };
    }
    case userActions.UserActions.SET_USER_PREFERENCES: {
      return {
        ...state,
        userPreferences: action.payload
      };
    }
    case userActions.UserActions.LOAD_PREFERENCES_SUCCESS: {
      return {
        ...state,
        preferences: action.payload
      };
    }
    case userActions.UserActions.LOAD_PREFERENCES_FAILED: {
      return {
        ...state,
        preferences: null,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_PREFERENCE_OPTIONS_SUCCESS: {
      return {
        ...state,
        preferenceOptions: action.payload
      };
    }
    case userActions.UserActions.LOAD_PREFERENCE_OPTIONS_FAILED: {
      return {
        ...state,
        preferenceOptions: null,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_USER_PREFERENCES_SUCCESS: {
      return {
        ...state,
        userPreferences: action.payload
      };
    }
    case userActions.UserActions.LOAD_USER_PREFERENCES_FAILED: {
      return {
        ...state,
        userPreferences: null,
        error: action.payload
      };
    }
    case userActions.UserActions.UPDATE_PREFERENCE_SUCCESS: {
      if (
        action.payload &&
        state.userPreferences &&
        state.userPreferences.length
      ) {
        state.userPreferences.splice(
          state.userPreferences.findIndex(
            (userPreference: IUserPreference) =>
              Number(userPreference.preference_id) ===
              Number(action.payload.preference_id)
          ),
          1,
          action.payload
        );
      }
      return {
        ...state,
        message: { success: "Changes updated successfully" }
      };
    }
    case userActions.UserActions.UPDATE_PREFERENCE_FAILED: {
      return {
        ...state,
        error: action.payload
      };
    }
    case userActions.UserActions.RESET_USER_PREFERENCES_TO_DEFAULT_SUCCESS: {
      return {
        ...state,
        message: { success: "Changes updated successfully" }
      };
    }
    case userActions.UserActions.RESET_USER_PREFERENCES_TO_DEFAULT_FAILED: {
      return {
        ...state,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_USER_BASE_LOCATIONS_SUCCESS: {
      return {
        ...state,
        baseLocations: action.payload
      };
    }
    case userActions.UserActions.LOAD_USER_BASE_LOCATIONS_FAILED: {
      return {
        ...state,
        baseLocations: null,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_USER_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload
      };
    }
    case userActions.UserActions.LOAD_USER_ROLES_FAILED: {
      return {
        ...state,
        roles: null,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_ACCESS_PERMISSIONS_FOR_ROLE_SUCCESS: {
      return {
        ...state,
        accessRoleAndPermissions: action.payload
      };
    }
    case userActions.UserActions.LOAD_ACCESS_PERMISSIONS_FOR_ROLE_FAILED: {
      return {
        ...state,
        accessRoleAndPermissions: null,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_USER_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: action.payload,
        error: null
      };
    }
    case userActions.UserActions.LOAD_USER_GROUPS_FAILED: {
      return {
        ...state,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_GROUP_USERS_SUCCESS: {
      if (action.payload && state.groups) {
        state.groups.filter((group: IGroup) => {
          if (Number(group.id) === Number(action.payload[0].group_id)) {
            group.users = action.payload;
          }
        });
      }
      return {
        ...state,
        error: null
      };
    }
    case userActions.UserActions.LOAD_GROUP_USERS_FAILED: {
      return {
        ...state,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_WORKBENCH_PERMISSION_FOR_ROLE_SUCCESS: {
      return {
        ...state,
        accessWorkbenchPermission: action.payload,
        error: null
      };
    }
    case userActions.UserActions.LOAD_WORKBENCH_PERMISSION_FOR_ROLE_FAILED: {
      return {
        ...state,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_FEATURE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        featurePermissions: action.payload
      };
    }
    case userActions.UserActions.LOAD_FEATURE_PERMISSIONS_FAILED: {
      return {
        ...state,
        featurePermissions: null,
        error: action.payload
      };
    }
    case userActions.UserActions.LOAD_ACCESS_FEATURE_PERMISSIONS_FOR_ROLE_SUCCESS: {
      return {
        ...state,
        accessFeaturePermissions: action.payload
      };
    }
    case userActions.UserActions.LOAD_ACCESS_FEATURE_PERMISSIONS_FOR_ROLE_FAILED: {
      return {
        ...state,
        accessFeaturePermissions: null,
        error: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}
