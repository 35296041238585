import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';
import { IEntity } from '../../core/models/entity';

export interface EntityState {
  entities: [IEntity];
  message: any;
}

export const initialState: EntityState = {
  entities: null,
  message: null
} as EntityState;

export const getApplicationState = (state: ApplicationState) => state;

// exports entity from ApplicationState
export const entity = createSelector(getApplicationState, (state: ApplicationState) => state.entity);
// exports entities from ApplicationState.entity
export const entities = createSelector(getApplicationState, (state: ApplicationState) => state.entity.entities);