import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    // loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
    loadChildren: './modules/login/login.module#LoginModule'
  },
  {
    path: '',
    // loadChildren: () => import('./modules/ark-container/ark-container.module').then(mod => mod.ArkContainerModule)
    loadChildren: './modules/ark-container/ark-container.module#ArkContainerModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
