import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { getError, getMessage } from 'src/app/store/user/user.state';
import { ApplicationState } from 'src/app/app.state';
import { RecoverPassword, RecoverPasswordSuccess, RecoverPasswordFailed } from 'src/app/store/user';
import { Observable } from 'rxjs';
import { FooterService } from 'src/app/core/services/footer';
import { filter, skip, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.css']
})
export class ForgotPasswordFormComponent implements OnInit {
  @Output() back = new EventEmitter();
  errorData$: Observable<any>;
  message$: Observable<any>;
  hide_show_password:boolean=false
  showPassword: string = 'password'; 
  showPasswordConfirmPass: string = 'password'; 
  
  copyright$: any = {};
 
  forgotPasswordForm: FormGroup;
  showmessage: boolean = false;
  errorMessage: boolean = false;
  successMessage: boolean = false;

  constructor(private store: Store<ApplicationState>, private footerService: FooterService,
    private router: Router) { }

  ngOnInit() {
    this.initialiseForm();
    this.findByCopyright();
    this.errorData$ = this.store.select(getError);
    this.message$ = this.store.select(getMessage);
  }

  initialiseForm = (): void => {
    this.forgotPasswordForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(5),
      Validators.pattern(/^(?=.*[a-z]).+$/)]),
      confirm_password: new FormControl('', [Validators.required,Validators.minLength(5),
        Validators.pattern(/^(?=.*[a-z]).+$/)]),
      recovery_email: new FormControl('', [Validators.required, Validators.pattern(/^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]*\.([a-z]{2,4})$/)]),

    }, { validators: this.passwordMatchValidator });


  }

  get username() {
    return this.forgotPasswordForm.get('username');
  }
  get recovery_email() {
    return this.forgotPasswordForm.get('recovery_email');
  }

  get newPassword() {
    return this.forgotPasswordForm.get('newPassword');

  }

  get confirm_password(){
    return this.forgotPasswordForm.get('confirm_password');
  }

  resetPassword = (): void => {
    this.store.dispatch(new RecoverPassword(this.forgotPasswordForm.value));
    this.message$
      .pipe(skip(1))
      .subscribe(messageData => {
        if (messageData.message == null) {
          this.successMessage = false;
          this.errorMessage = true;
        } else {
          //success message
          this.successMessage = true;
          this.errorMessage = false;
          this.initialiseForm();
          
          setTimeout(() => {
            this.router.navigate(['']);
          }, 3000)

        }
      });

    this.errorData$
      .pipe(skip(1))
      .subscribe(err => {
        if (err) {
          this.successMessage = false;
          this.errorMessage = true;
        }
      });
    //  this.showmessage=true
    //  setTimeout(()=>{
    //  this.router.navigate([''],{ queryParams: {fromFogotPass:true} });
    //  this.showmessage=false;
    //  },3000)

  }

  backEvent = (): void => {
    this.router.navigate([''])
  }
  togglePasswordVisibility() {
    this.showPassword = this.showPassword === 'password' ? 'text' : 'password';
   
  }
  togglePasswordVisibilityConfirmPass() {
    this.showPasswordConfirmPass = this.showPasswordConfirmPass === 'password' ? 'text' : 'password';
   
  }
  
  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {

    const newPassword2 = control.get('newPassword');
    const confirmPassword2 = control.get('confirm_password');

    if (newPassword2.value !== confirmPassword2.value) {
      return { 'passwordMismatch': true };
    }

    return null;
  }
  findByCopyright() {
    this.footerService.findByCopyrightNoAuth()
      .subscribe(data => {
        if (data) {
          this.copyright$ = data[0];
        }
      }, (error) => {
        console.log('error', error);
      })
  };

}
