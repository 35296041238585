import * as moduleActions from './module.action';

export function moduleReducer(state: any, action: moduleActions.ModuleAction): any {
  switch (action.type) {
    case moduleActions.ModuleActions.LOAD_MODULES: {
      return {
        ...state,
      };
    }
    case moduleActions.ModuleActions.LOAD_MODULES_SUCCESS: {
      return {
        ...state,
        modules: action.payload,
        message: null
      };
    }
    case moduleActions.ModuleActions.LOAD_MODULES_FAILED: {
      return {
        ...state,
        modules: null,
        message: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}
