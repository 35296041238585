import { Injectable } from "@angular/core";
import { HttpClient} from "@angular/common/http";
import {
  IAuthenticatedUser,
  IUser,
  IUserBaseLocation,
  IPreference,
  IPreferenceOption,
  IUserRole,
  IRolePermission,
  IGroup,
  IWorkbenchPermission,
  IFeaturePermission,
  IRoleFeaturePermission
} from "../models/user";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private http: HttpClient, private router: Router) { }

  videoElement: any;

  public getToken() {
    return sessionStorage.getItem("token");
  }

  public stopVideo() {
    if (this.videoElement) this.videoElement.pause();
  }

  public getUser() {
    return sessionStorage.getItem("user");
  }

  public getSession() {
    return sessionStorage.getItem("session-id");
  }

  public authenticate(
    username: string,
    password: string
  ): Observable<IAuthenticatedUser> {
    return this.http
      .post<any>(
        `${environment.api_services.authService.url}/authentication/authenticate`,
        {
          username,
          password,
          module_id: 13
        }
      )
      .pipe(catchError(this.handleError));
  }

  public logout(): Observable<boolean> {
    const queryParam = { module_id: "13" };
    return this.http.get<any>(`${environment.api_services.authService.url}/api/user/logout`, { params: queryParam })
      .pipe(catchError(this.handleError));
  }

  public removeSessionId(sessionId: string, moduleId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.api_services.authService.url}/api/user/logout/${sessionId}`,{ 
      params: { module_id: moduleId.toString() }
      }).pipe(
        map(response => response.data)
      );
  }

  // Get session details
  public getSessionDetails(sessionId: string): Observable<any> {
    return this.http.get<any>(environment.api_services.authService.url + "/session/" + sessionId).pipe(
      map(response => {
        if (response && response.data && response.data.length > 0) {
          return response.data[0];
        } else {
          return null;
        }
      }),
      catchError(this.handleError)
    );
  }
  
  public recoverPassword(data: any): Observable<any> {
    return this.http
      .put<any>(
        `${environment.api_services.authService.url}/user/forgotpassword`,
        data
      )
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }
  public UpdatePasswordOfUnblockUser(data: any):Observable<any> {
  
    return this.http
      .put<any>(
        `${environment.api_services.authService.url}/user/updateUnblockedpassword/${data.id}`,
        data   
      )
      .pipe(
       
        map((response: any) => response),
       catchError(this.handleError)
      );
  }

  public getPasswordPolicy() {
    return this.http
      .get<any>(
        `${environment.api_services.authService.url}/api/password/policy`
      )
      .pipe(
        map((response: any) => {
          if (response && response.data && response.data[0]) {
            return response.data[0];
          } else {
            return [];
          }
        }),
        catchError(this.handleError)
      );
  }

  public resetPassword(data: any) {
    return this.http
      .put<any>(
        `${environment.api_services.authService.url}/api/user/passwordreset`,
        data
      )
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  public getUserDetails(userId: number): Observable<IUser> {
    return this.http
      .get<any>(
        `${environment.api_services.authService.url}/api/user/details/user/${userId}`
      )
      .pipe(
        map((response: any) => response.data[0]),
        catchError(this.handleError)
      );
  }

  public updateUser(user: any) {
    return this.http
      .put<any>(
        `${environment.api_services.authService.url}/api/user/userId/${user.user_id}`,
        user
      )
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  public updateUserDetails(userDetails: IUser) {
    return this.http
      .put<any>(
        `${environment.api_services.authService.url}/api/user/details/${userDetails.id}`,
        userDetails
      )
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  public getPreferences(): Observable<IPreference[]> {
    return this.http
      .get<any>(`${environment.api_services.authService.url}/api/preferences`)
      .pipe(
        map((response: any) => {
          if (response && response.data) {
            return response.data;
          } else {
            return [];
          }
        }),
        catchError(this.handleError)
      );
  }

  public getPreferenceOptions(): Observable<IPreferenceOption[]> {
    return this.http
      .get<any>(
        `${environment.api_services.authService.url}/api/preferences/options`
      )
      .pipe(
        map((response: any) => {
          if (response && response.data) {
            return response.data;
          } else {
            return [];
          }
        }),
        catchError(this.handleError)
      );
  }

  public getPreferencesByUserId(userId: number): Observable<IPreference[]> {
    return this.http
      .get<any>(
        `${environment.api_services.authService.url}/api/preferences/user/${userId}`
      )
      .pipe(
        map((response: any) => {
          if (response && response.data) {
            return response.data;
          } else {
            return [];
          }
        }),
        catchError(this.handleError)
      );
  }

  public updatePreference(preference: any) {
    return this.http.put(
      `${environment.api_services.authService.url}/api/preferences/user/${preference.user_id}/preference/${preference.preference_id}`,
      preference
    ).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public resetPreferencesToDefault(userId: number) {
    return this.http.delete(`${environment.api_services.authService.url}/api/preferences/user/${userId}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getUserBaseLocations(userId: number): Observable<IUserBaseLocation[]> {
    return this.http.get<any>(`${environment.api_services.authService.url}/api/user/${userId}/baselocation`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  }

  public getAccessRoles(userId: number): Observable<IUserRole[]> {
    return this.http.get<any>(`${environment.api_services.authService.url}/api/authorization/userrole/user_id/${userId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  }

  public getPermissionsByRoleAndUser(
    roleId: number,
    userId: number
  ): Observable<IRolePermission[]> {
    return this.http.get<any>(`${environment.api_services.authService.url}/api/authorization/accessrolepermission/role/${roleId}/user/${userId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  }

  public getUserGroups(userId: number): Observable<IGroup[]> {
    return this.http.get<any>(`${environment.api_services.authService.url}/api/groups/usergroups/${userId}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getGroupUsers(groupId: number): Observable<IUser[]> {
    return this.http.get<any>(`${environment.api_services.authService.url}/api/groups/${groupId}/users`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  }

  public getWorkbenchPermissionByRole(
    roleId: number
  ): Observable<IWorkbenchPermission> {
    return this.http.get<any>(`${environment.api_services.authService.url}/api/access/workbench/role/${roleId}`).pipe(
      map((response: any) => {
        if (response) {
          return response[0];
        } else {
          return {};
        }
      }),
      catchError(this.handleError)
    );
  }

  public getFeaturePermissions(): Observable<IFeaturePermission[]> {
    return this.http.get<any>(`${environment.api_services.authService.url}/api/feature/features`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getFeaturePermissionsByRole(roleId: number): Observable<IRoleFeaturePermission[]> {
    return this.http.get<any>(`${environment.api_services.authService.url}/api/feature/role/${roleId}/feature/access`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  }

  handleError(error) {
    return throwError(error);
  }
}
