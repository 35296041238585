import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceHealthCheckService {
  constructor(private http: HttpClient) { }

  public getEntityServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.entityService.url}/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getItemRetailServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.itemRetailService.url}/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getJobServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.jobService.url}/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getLocationServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.locationService.url}/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getMTOServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.mtoService.url}/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getUDDServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.uddService.url}/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getUserServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.authService.url}/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getVendorServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.vendorService.url}/api/vendor/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getCloudCartServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.cloudCartService.url}/model/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getTaxServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.taxService.url}/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getDeliveryBookingServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.deliveryBookingService.url}/api/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getOrderAdvisorServiceHealthCheck(): Observable<any> {
    return this.http.get<any>(`${environment.api_services.orderAdvisorService.url}/api/service/healthcheck`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  handleError(error) {
    return throwError(error);
  }

}
