import { Action } from '@ngrx/store';

export enum EntityActions {
  LOAD_ENTITIES = '[Entity] Load Entities',
  LOAD_ENTITIES_SUCCESS = '[Entity] Load Entities Success',
  LOAD_ENTITIES_FAILED = '[Entity] Load Entities Failed'
}

export class LoadEntities implements Action {
  readonly type: string = EntityActions.LOAD_ENTITIES;

  constructor(public payload: any) {
  }
}

export class LoadEntitiesSuccess implements Action {
  readonly type: string = EntityActions.LOAD_ENTITIES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadEntitiesFailed implements Action {
  readonly type: string = EntityActions.LOAD_ENTITIES_FAILED;

  constructor(public payload: any) {
  }
}

export type EntityAction = LoadEntities
  | LoadEntitiesSuccess
  | LoadEntitiesFailed;
