import * as datalakeActions from "./datalake.action";

import { IDocument } from "src/app/core/models/datalake";

export function datalakeReducer(
  state: any,
  action: datalakeActions.DatalakeAction
): any {
  switch (action.type) {
    case datalakeActions.DatalakeActions.LOAD_PROFILE_LAKE_DETAILS_SUCCESS: {
      return {
        ...state,
        profileLake: action.payload,
        message: null
      };
    }
    case datalakeActions.DatalakeActions.LOAD_PROFILE_LAKE_DETAILS_FAILED: {
      return {
        ...state,
        profileLake: null,
        message: action.payload
      };
    }
    case datalakeActions.DatalakeActions.LOAD_USER_DIRECTORY_BY_LAKE_SUCCESS: {
      return {
        ...state,
        userDirectory: action.payload,
        message: null
      };
    }
    case datalakeActions.DatalakeActions.LOAD_USER_DIRECTORY_BY_LAKE_FAILED: {
      return {
        ...state,
        userDirectory: null,
        message: action.payload
      };
    }
    case datalakeActions.DatalakeActions
      .LOAD_USER_DOCUMENTS_BY_LAKE_AND_USER_DIRECTORY_SUCCESS: {
      return {
        ...state,
        userDocuments: action.payload,
        message: null
      };
    }
    case datalakeActions.DatalakeActions
      .LOAD_USER_DOCUMENTS_BY_LAKE_AND_USER_DIRECTORY_FAILED: {
      return {
        ...state,
        message: action.payload
      };
    }
    case datalakeActions.DatalakeActions.SET_PROFILE_LAKE_DETAILS: {
      return {
        ...state,
        profileLake: action.payload
      };
    }
    case datalakeActions.DatalakeActions.SET_USET_DERECTORY: {
      return {
        ...state,
        userDirectory: action.payload
      };
    }
    case datalakeActions.DatalakeActions.SET_USET_DOCUMENTS: {
      return {
        ...state,
        userDocuments: action.payload
      };
    }
    case datalakeActions.DatalakeActions.UPLOAD_DOCUMENT: {
      return {
        ...state,
        isUploading: true
      };
    }
    case datalakeActions.DatalakeActions.UPLOAD_DOCUMENT_SUCCESS: {
      if (!state.userDocuments) {
        state.userDocuments = [];
      }
      return {
        ...state,
        isUploading: false,
        userDocuments: [...state.userDocuments, ...action.payload],
        message: "Document uploaded successfully."
      };
    }
    case datalakeActions.DatalakeActions.UPLOAD_DOCUMENT_FAILED: {
      return {
        ...state,
        isUploading: false,
        message: action.payload
      };
    }

    case datalakeActions.DatalakeActions.REMOVE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        userDocuments: state.userDocuments.filter(
          (userDocument: IDocument) => userDocument.id !== action.payload
        ),
        message: "Document removed successfully."
      };
    }
    case datalakeActions.DatalakeActions.REMOVE_DOCUMENT_FAILED: {
      return {
        ...state,
        message: action.payload
      };
    }

    case datalakeActions.DatalakeActions.LOAD_LAST_DB_BACKUP_DATE_SUCCESS: {
      return {
        ...state,
        dbLastBackupDate: action.payload
      };
    }

    case datalakeActions.DatalakeActions.LOAD_LAST_DB_BACKUP_DATE_FAILED: {
      return {
        ...state,
        dbLastBackupDate: {}
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}
