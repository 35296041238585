import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { IEntity } from '../models/entity';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  constructor(private http: HttpClient) { }

  /* get package entities */
  public getEntities(): Observable<IEntity[]> {
    return this.http.get<any>(`${environment.api_services.entityService.url}/api/entity`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    return throwError(error);
  }

}
