import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';
import { IModule } from '../../core/models/module';

export interface ModuleState {
  modules: [IModule];
  message: any;
}

export const initialState: ModuleState = {
  modules: null,
  message: null
} as ModuleState;

export const getApplicationState = (state: ApplicationState) => state;

// exports module from ApplicationState
export const module = createSelector(getApplicationState, (state: ApplicationState) => state.module);
// exports modules from ApplicationState.module
export const modules = createSelector(getApplicationState, (state: ApplicationState) => state.module.modules);