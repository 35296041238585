import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import {
  switchMap,
  map,
  tap,
  catchError,
  withLatestFrom,
  filter,
  exhaustMap,
  flatMap
} from "rxjs/operators";
import { of } from "rxjs";

import * as userActions from "./user.action";
import * as datalakeActions from "./../datalake/datalake.action";
import { ApplicationState } from "src/app/app.state";
import { UserService } from "./../../core/services/user";
import {
  userProfile,
  preferences,
  preferenceOptions,
  userPreferences,
  userBaseLocations,
  userRoles,
  accessRoleAndPermissions,
  groups,
  accessWorkbenchPermission,
  featurePermissions,
  accessFeaturePermissions
} from "./user.state";
import { IGroup } from "src/app/core/models/user";

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    public store: Store<ApplicationState>,
    private router: Router
  ) { }

  @Effect()
  public logoutEffect$ = this.actions$.pipe(
    ofType(userActions.LoginActions.LOGOUT),
    switchMap(() =>
      this.userService.logout().pipe(
        map(() => new userActions.LogoutSuccess(null)),
        catchError(error => of(new userActions.LogoutFail(error)))
      )
    )
  );

  @Effect()
  public logoutSuccessEffect$ = this.actions$.pipe(
    ofType(userActions.LoginActions.LOGOUT),
    flatMap(() => [
      new userActions.LogoutSuccess(null),
      new datalakeActions.SetProfileLakeDetails(null),
      new datalakeActions.SetDirectory(null),
      new datalakeActions.SetDocuments(null)
    ])
  );

  @Effect()
  public loginEffect$ = this.actions$.pipe(
    ofType(userActions.LoginActions.LOGIN),
    switchMap((action: userActions.Login) => {
      return this.userService
        .authenticate(action.payload.username, action.payload.password)
        .pipe(
          map(user => new userActions.LoginSuccess(user)),
          catchError(error => {
            if (error.error) {
              return of(new userActions.LoginFailed(error.error.error));
            }
            return of(new userActions.LoginFailed(error));
          })
        );
    })
  );

  @Effect()
  public recoverPasswordEffect$ = this.actions$.pipe(
    ofType(userActions.LoginActions.RECOVER_PASSWORD),
    switchMap((action: userActions.RecoverPassword) => {
      return this.userService.recoverPassword(action.payload).pipe(
        map(user => new userActions.RecoverPasswordSuccess(user)),
        catchError(error => of(new userActions.RecoverPasswordFailed(error)))
      );
    })
  );


  

  // @Effect()
  // public UpdateUnblockUsersPasswordEffect$ = this.actions$.pipe(
  //   ofType(userActions.LoginActions.UPDATE_UNBLOCK_USER_PASSWORD),
  //   switchMap((action: userActions.UpdateUnblockUsersPassword) =>{
  //     return this.userService.UpdatePasswordOfUnblockUser(action.payload).pipe(
  //       map(user => new userActions.UpdateUnblockUsersPasswordSuccess(user)),
  //       catchError(error => of(new userActions.UpdateUnblockUsersPasswordFailed(error)))
  //     )
  //     })
  // );
  
  

  @Effect()
  public authenticationCheck = this.actions$.pipe(
    ofType(userActions.LoginActions.AUTHENTICATION_CHECK),
    map(action => (action as any).payload),
    map(user => {
      if (user) {
        return new userActions.AuthenticationSuccess(user);
      } else {
        return new userActions.AuthenticationFail(null);
      }
    })
  );

  @Effect({ dispatch: false })
  public loginSuccess$ = this.actions$.pipe(
    ofType(userActions.LoginActions.LOGIN_SUCCESS),
    map(action => (action as any).payload),
    map(user => {
      sessionStorage.setItem("token", user.token);
      sessionStorage.setItem("session-id", user.session_id);
      sessionStorage.setItem("user", JSON.stringify(user));
      this.router.navigate(["/dashboard"]);
    })
  );

  @Effect({ dispatch: false })
  public logoutSuccess$ = this.actions$.pipe(
    ofType(userActions.LoginActions.LOGOUT_SUCCESS),
    map(() => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("session-id");
      sessionStorage.removeItem("user");
      this.router.navigate(["/login"]);
    })
  );

  @Effect()
  public loadUserProfileEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_USER_PROFILE),
    withLatestFrom(this.store.pipe(select(userProfile))),
    filter(([action, userProfile]) => {
      if (
        userProfile &&
        userProfile.id == (action as userActions.LoadUserProfile).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: userActions.LoadUserProfile) =>
      this.userService.getUserDetails(action.payload).pipe(
        map(response => new userActions.LoadUserProfileSuccess(response)),
        catchError(error => of(new userActions.LoadUserProfileFailed(error)))
      )
    )
  );

  @Effect()
  public loadPasswordPolicyEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_PASSWORD_POLICY),
    exhaustMap(() =>
      this.userService.getPasswordPolicy().pipe(
        map(response => new userActions.LoadPasswordPolicySuccess(response)),
        catchError(error => of(new userActions.LoadPasswordPolicyFailed(error)))
      )
    )
  );

  @Effect()
  public resetPasswordEffect = this.actions$.pipe(
    ofType(userActions.UserActions.RESET_PASSWORD),
    exhaustMap((action: userActions.ResetPassword) =>
      this.userService.resetPassword(action.payload).pipe(
        map(response => new userActions.ResetPasswordSuccess(response)),
        catchError(error => of(new userActions.ResetPasswordFailed(error)))
      )
    )
  );

  @Effect()
  public updateUserEffect = this.actions$.pipe(
    ofType(userActions.UserActions.UPDATE_USER),
    flatMap((action: userActions.UpdateUser) =>
      this.userService.updateUser(action.payload).pipe(
        map(() => new userActions.UpdateUserSuccess(action.payload)),
        catchError(error => of(new userActions.UpdateUserFailed(error)))
      )
    )
  );

  @Effect()
  public updateUserDetailsEffect = this.actions$.pipe(
    ofType(userActions.UserActions.UPDATE_USER_DETAILS),
    flatMap((action: userActions.UpdateUserDetails) =>
      this.userService.updateUserDetails(action.payload).pipe(
        map(() => new userActions.UpdateUserDetailsSuccess(action.payload)),
        catchError(error => of(new userActions.UpdateUserDetailsFailed(error)))
      )
    )
  );

  @Effect()
  public loadPreferencesEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_PREFERENCES),
    withLatestFrom(this.store.pipe(select(preferences))),
    filter(([_, preferences]) => {
      if (preferences) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.userService.getPreferences().pipe(
        map(response => new userActions.LoadPreferencesSuccess(response)),
        catchError(error => of(new userActions.LoadPreferencesFailed(error)))
      )
    )
  );

  @Effect()
  public loadPreferenceOptionsEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_PREFERENCE_OPTIONS),
    withLatestFrom(this.store.pipe(select(preferenceOptions))),
    filter(([_, preferenceOptions]) => {
      if (preferenceOptions) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.userService.getPreferenceOptions().pipe(
        map(response => new userActions.LoadPreferenceOptionsSuccess(response)),
        catchError(error =>
          of(new userActions.LoadPreferenceOptionsFailed(error))
        )
      )
    )
  );

  @Effect()
  public loadUserPreferencesEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_USER_PREFERENCES),
    withLatestFrom(this.store.pipe(select(userPreferences))),
    filter(([_, userPreferences]) => {
      if (userPreferences) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    flatMap((action: userActions.LoadUserPreferences) =>
      this.userService.getPreferencesByUserId(action.payload).pipe(
        map(response => new userActions.LoadUserPreferencesSuccess(response)),
        catchError(error =>
          of(new userActions.LoadUserPreferencesFailed(error))
        )
      )
    )
  );

  @Effect()
  public updatePreferenceEffect = this.actions$.pipe(
    ofType(userActions.UserActions.UPDATE_PREFERENCE),
    flatMap((action: userActions.UpdatePreference) =>
      this.userService.updatePreference(action.payload).pipe(
        map(() => new userActions.UpdatePreferenceSuccess(action.payload)),
        catchError(error => of(new userActions.UpdatePreferenceFailed(error)))
      )
    )
  );

  @Effect()
  public resetUserPreferencesToDefaultEffect = this.actions$.pipe(
    ofType(userActions.UserActions.RESET_USER_PREFERENCES_TO_DEFAULT),
    flatMap((action: userActions.ResetUserPreferencesToDefault) =>
      this.userService.resetPreferencesToDefault(action.payload).pipe(
        map(
          () =>
            new userActions.ResetUserPreferencesToDefaultSuccess(action.payload)
        ),
        catchError(error =>
          of(new userActions.ResetUserPreferencesToDefaultFailed(error))
        )
      )
    )
  );

  @Effect()
  public resetUserPreferencesToDefaultSuccessEffect = this.actions$.pipe(
    ofType(userActions.UserActions.RESET_USER_PREFERENCES_TO_DEFAULT_SUCCESS),
    flatMap((action: userActions.ResetUserPreferencesToDefaultSuccess) => [
      new userActions.SetUserPreferences(null),
      new userActions.LoadUserPreferences(action.payload)
    ]),
    catchError(error =>
      of(new userActions.ResetUserPreferencesToDefaultFailed(error))
    )
  );

  @Effect()
  public loadUserBaseLocationsEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_USER_BASE_LOCATIONS),
    withLatestFrom(this.store.pipe(select(userBaseLocations))),
    filter(([action, userBaseLocations]) => {
      if (
        userBaseLocations &&
        userBaseLocations[0] &&
        userBaseLocations[0].user_id ===
        (action as userActions.LoadUserBaseLocations).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: userActions.LoadUserBaseLocations) =>
      this.userService.getUserBaseLocations(action.payload).pipe(
        map(response => new userActions.LoadUserBaseLocationsSuccess(response)),
        catchError(error =>
          of(new userActions.LoadUserBaseLocationsFailed(error))
        )
      )
    )
  );

  @Effect()
  public loadUserRolesEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_USER_ROLES),
    withLatestFrom(this.store.pipe(select(userRoles))),
    filter(([action, userRoles]) => {
      if (
        userRoles &&
        userRoles[0] &&
        userRoles[0].user_id === (action as userActions.LoadUserRoles).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: userActions.LoadUserRoles) =>
      this.userService.getAccessRoles(action.payload).pipe(
        map(response => new userActions.LoadUserRolesSuccess(response)),
        catchError(error => of(new userActions.LoadUserRolesFailed(error)))
      )
    )
  );

  @Effect()
  public loadAccessPermissionsForRoleEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_ACCESS_PERMISSIONS_FOR_ROLE),
    withLatestFrom(this.store.pipe(select(accessRoleAndPermissions))),
    filter(([action, accessRoleAndPermissions]) => {
      if (
        accessRoleAndPermissions &&
        accessRoleAndPermissions[0] &&
        accessRoleAndPermissions[0].role_id ===
        (action as userActions.LoadAccessPermissionsForRole).payload.roleId
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: userActions.LoadAccessPermissionsForRole) =>
      this.userService
        .getPermissionsByRoleAndUser(
          action.payload.roleId,
          action.payload.userId
        )
        .pipe(
          map(
            response =>
              new userActions.LoadAccessPermissionsForRoleSuccess(response)
          ),
          catchError(error =>
            of(new userActions.LoadAccessPermissionsForRoleFailed(error))
          )
        )
    )
  );

  @Effect()
  public LoadWorkbenchPermissionForRoleEffect$ = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_WORKBENCH_PERMISSION_FOR_ROLE),
    withLatestFrom(this.store.pipe(select(accessWorkbenchPermission))),
    filter(([action, accessWorkbenchPermission]) => {
      if (
        accessWorkbenchPermission &&
        accessWorkbenchPermission.role_id ===
        (action as userActions.LoadWorkbenchPermissionForRole).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: userActions.LoadWorkbenchPermissionForRole) =>
      this.userService.getWorkbenchPermissionByRole(action.payload).pipe(
        map(
          response =>
            new userActions.LoadWorkbenchPermissionForRoleSuccess(response)
        ),
        catchError(error =>
          of(new userActions.LoadWorkbenchPermissionForRoleFailed(error))
        )
      )
    )
  );

  @Effect()
  public loadFeaturePermissionsEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_FEATURE_PERMISSIONS),
    withLatestFrom(this.store.pipe(select(featurePermissions))),
    filter(([_, featurePermissions]) => {
      if (featurePermissions) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.userService.getFeaturePermissions().pipe(
        map(
          response => new userActions.LoadFeaturePermissionsSuccess(response)
        ),
        catchError(error =>
          of(new userActions.LoadFeaturePermissionsFailed(error))
        )
      )
    )
  );

  @Effect()
  public loadAccessFeaturePermissionsForRoleEffect = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_ACCESS_FEATURE_PERMISSIONS_FOR_ROLE),
    withLatestFrom(this.store.pipe(select(accessFeaturePermissions))),
    filter(([action, accessFeaturePermissions]) => {
      if (
        accessFeaturePermissions &&
        accessFeaturePermissions[0] &&
        accessFeaturePermissions[0].role_id ===
        (action as userActions.LoadAccessFeaturePermissionsForRole).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: userActions.LoadAccessFeaturePermissionsForRole) =>
      this.userService.getFeaturePermissionsByRole(action.payload).pipe(
        map(
          response =>
            new userActions.LoadAccessFeaturePermissionsForRoleSuccess(response)
        ),
        catchError(error =>
          of(new userActions.LoadAccessFeaturePermissionsForRoleFailed(error))
        )
      )
    )
  );

  @Effect()
  public LoadUserGroupsEffect$ = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_USER_GROUPS),
    withLatestFrom(this.store.pipe(select(groups))),
    filter(([_, groups]) => {
      if (groups) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: userActions.LoadUserGroups) =>
      this.userService.getUserGroups(action.payload).pipe(
        map(response => new userActions.LoadUserGroupsSuccess(response)),
        catchError(error => of(new userActions.LoadUserGroupsFailed(error)))
      )
    )
  );

  @Effect()
  public LoadUserGroupsSuccessEffect$ = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_USER_GROUPS_SUCCESS),
    exhaustMap((action: userActions.LoadUserGroupsSuccess) => {
      const actions = [];
      Array.from(action.payload).forEach((group: IGroup) => {
        actions.push(new userActions.LoadGroupUsers(group.id));
      });
      return actions;
    })
  );

  @Effect()
  public LoadGroupUsersEffect$ = this.actions$.pipe(
    ofType(userActions.UserActions.LOAD_GROUP_USERS),
    flatMap((action: userActions.LoadGroupUsers) =>
      this.userService.getGroupUsers(action.payload).pipe(
        map(response => new userActions.LoadGroupUsersSuccess(response)),
        catchError(error => of(new userActions.LoadGroupUsersFailed(error)))
      )
    )
  );
}
