import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, catchError, withLatestFrom, filter, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as moduleActions from './module.action';
import { ModuleService } from './../../core/services/module';
import { ApplicationState } from 'src/app/app.state';
import { modules } from './module.state';

@Injectable()
export class ModuleEffects {
  constructor(
    private actions$: Actions,
    private moduleService: ModuleService,
    public store: Store<ApplicationState>
  ) { }

  @Effect()
  public loadModulesEffect = this.actions$.pipe(
    ofType(moduleActions.ModuleActions.LOAD_MODULES),
    withLatestFrom(this.store.pipe(select(modules))),
    // filter(([_, modules]) => {
    //   if (modules) { return false } return true
    // }),
    exhaustMap(() =>
      this.moduleService.getModules().pipe(
        map(response => (
          new moduleActions.LoadModulesSuccess(response)
        )),
        catchError((error) => of(
          new moduleActions.LoadModulesFailed(error)
        ))
      )
    )
  );
}

