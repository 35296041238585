import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { map, catchError, flatMap } from "rxjs/operators";
import { of } from "rxjs";

import * as serviceHealthCheckActions from "./service.health.check.action";
import { ApplicationState } from "src/app/app.state";
import { ServiceHealthCheckService } from "../../core/services/service.health.check";

@Injectable()
export class ServiceHealthCheckEffects {
  constructor(
    private actions$: Actions,
    private serviceHealthCheckService: ServiceHealthCheckService,
    public store: Store<ApplicationState>
  ) { }

  @Effect()
  public LoadEntityServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_ENTITY_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getEntityServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadEntityServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadEntityServiceHealthCheckFailed(error)))
    ))
  );

  @Effect()
  public LoadItemRetailServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_ITEM_RETAIL_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getItemRetailServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadItemRetailServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadItemRetailServiceHealthCheckFailed(error)))
    ))
  );

  @Effect()
  public LoadJobServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_JOB_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getJobServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadJobServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadJobServiceHealthCheckFailed(error)))
    ))
  );

  @Effect()
  public LoadLocationServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_LOCATION_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getLocationServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadLocationServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadLocationServiceHealthCheckFailed(error)))
    ))
  );

  @Effect()
  public LoadMTOServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_MTO_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getMTOServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadMTOServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadMTOServiceHealthCheckFailed(error)))
    ))
  );

  @Effect()
  public LoadUDDServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_UDD_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getUDDServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadUDDServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadUDDServiceHealthCheckFailed(error)))
    ))
  );

  @Effect()
  public LoadUserServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_USER_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getUserServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadUserServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadUserServiceHealthCheckFailed(error)))
    )    )
  );

  @Effect()
  public LoadVendorServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_VENDOR_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getVendorServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadVendorServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadVendorServiceHealthCheckFailed(error)))
    )    )
  );

  @Effect()
  public LoadCloudCartServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_CLOUD_CART_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getCloudCartServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadCloudCartServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadCloudCartServiceHealthCheckFailed(error)))
    ))
  );

  @Effect()
  public LoadTaxServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_TAX_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getTaxServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadTaxServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadTaxServiceHealthCheckFailed(error)))
    ))
  );

  @Effect()
  public LoadDeliveryBookingServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_DELIVERY_BOOKING_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getDeliveryBookingServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadDeliveryBookingServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadDeliveryBookingServiceHealthCheckFailed(error)))
    )    )
  );

  @Effect()
  public LoadOrderAdvisorServiceHealthCheckEffect$ = this.actions$.pipe(
    ofType(serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_ORDER_ADVISOR_SERVICE_HEALTH_CHECK),
    flatMap(() => this.serviceHealthCheckService.getOrderAdvisorServiceHealthCheck().pipe(
      map(response => new serviceHealthCheckActions.LoadOrderAdvisorServiceHealthCheckSuccess(response)),
      catchError(error => of(new serviceHealthCheckActions.LoadOrderAdvisorServiceHealthCheckFailed(error)))
    )    )
  );
}
