import { Action } from "@ngrx/store";

import {
  IUser,
  IPreference,
  IPreferenceOption,
  IUserPreference,
  IUserBaseLocation,
  IUserRole,
  IRolePermission,
  IGroup,
  IWorkbenchPermission,
  IFeaturePermission,
  IRoleFeaturePermission
} from "src/app/core/models/user";

export enum LoginActions {
  LOGIN = "[User] Login",
  LOGIN_SUCCESS = "[User] Login Success",
  LOGIN_FAILED = "[User] Login Failed",
  LOGOUT = "[User] Logout",
  LOGOUT_SUCCESS = "[User] Logout Success",
  LOGOUT_FAIL = "[User] Logout Fail",
  RECOVER_PASSWORD = "[User] Recover Password",
  UPDATE_UNBLOCK_PASSWORD = "[User] Update Unblock Password",
  UPDATE_UNBLOCK_USER_PASSWORD_SUCCESS = "[User] Update Unblock Password Success",
  UPDATE_UNBLOCK_USER_PASSWORD_FAILED = "[User] Update Unblock Password Failed",
  RECOVER_PASSWORD_SUCCESS = "[User] Recover Password Success",
  RECOVER_PASSWORD_FAILED = "[User] Recover Password Failed",
  AUTHENTICATION_CHECK = "[Auth] Authentication Check",
  AUTHENTICATION_SUCCESS = "[Auth] Authentication Success",
  AUTHENTICATION_FAIL = "[Auth] Authentication Fail",
  UPDATE_UNBLOCK_USER_PASSWORD = "[User] Update Unblock Password",


}
export enum UserActions {
  LOAD_USER_PROFILE = "[User] Load User Profile",
  LOAD_USER_PROFILE_SUCCESS = "[User] Load User Profile Success",
  LOAD_USER_PROFILE_FAILED = "[User] Load User Profile Failed",

  LOAD_PASSWORD_POLICY = "[User] Load Password Policy",
  LOAD_PASSWORD_POLICY_SUCCESS = "[User] Load Password Policy Success",
  LOAD_PASSWORD_POLICY_FAILED = "[User] Load Password Policy Failed",

  RESET_PASSWORD = "[User] Reset Password",
  RESET_PASSWORD_SUCCESS = "[User] Reset Password Success",
  RESET_PASSWORD_FAILED = "[User] Reset Password Failed",

  UPDATE_USER = "[User] Update User",
  UPDATE_USER_SUCCESS = "[User] Update User Success",
  UPDATE_USER_FAILED = "[User] Update User Failed",

  UPDATE_USER_DETAILS = "[User] Update User Details",
  UPDATE_USER_DETAILS_SUCCESS = "[User] Update User Details Success",
  UPDATE_USER_DETAILS_FAILED = "[User] Update User Details Failed",

  SET_MESSAGE = "[User] Set Message",
  SET_USER_PREFERENCES = "[User] Set User Preferences",

  LOAD_PREFERENCES = "[User] Load Preferences",
  LOAD_PREFERENCES_SUCCESS = "[User] Load Preferences Success",
  LOAD_PREFERENCES_FAILED = "[User] Load Preferences Failed",

  LOAD_PREFERENCE_OPTIONS = "[User] Load Preference Options",
  LOAD_PREFERENCE_OPTIONS_SUCCESS = "[User] Load Preference Options Success",
  LOAD_PREFERENCE_OPTIONS_FAILED = "[User] Load Preference Options Failed",

  LOAD_USER_PREFERENCES = "[User] Load User Preferences",
  LOAD_USER_PREFERENCES_SUCCESS = "[User] Load User Preferences Success",
  LOAD_USER_PREFERENCES_FAILED = "[User] Load User Preferences Failed",

  UPDATE_PREFERENCE = "[User] Update Preference",
  UPDATE_PREFERENCE_SUCCESS = "[User] Update Preference Success",
  UPDATE_PREFERENCE_FAILED = "[User] Update Preference Failed",

  RESET_USER_PREFERENCES_TO_DEFAULT = "[User] Reset User Preferences To Default",
  RESET_USER_PREFERENCES_TO_DEFAULT_SUCCESS = "[User] Reset User Preferences To Default Success",
  RESET_USER_PREFERENCES_TO_DEFAULT_FAILED = "[User] Reset User Preferences To Default Failed",

  LOAD_USER_BASE_LOCATIONS = "[User] Load User Base Locations",
  LOAD_USER_BASE_LOCATIONS_SUCCESS = "[User] Load User Base Locations Success",
  LOAD_USER_BASE_LOCATIONS_FAILED = "[User] Load User Base Locations Failed",

  LOAD_USER_ROLES = "[User] Load User Roles",
  LOAD_USER_ROLES_SUCCESS = "[User] Load User Roles Success",
  LOAD_USER_ROLES_FAILED = "[User] Load User Roles Failed",

  LOAD_ACCESS_PERMISSIONS_FOR_ROLE = "[User] Load Access Permissions For Role",
  LOAD_ACCESS_PERMISSIONS_FOR_ROLE_SUCCESS = "[User] Load Access Permissions For Role Success",
  LOAD_ACCESS_PERMISSIONS_FOR_ROLE_FAILED = "[User] Load Access Permissions For Role Failed",

  LOAD_WORKBENCH_PERMISSION_FOR_ROLE = "[User] Load Workbench Permission",
  LOAD_WORKBENCH_PERMISSION_FOR_ROLE_SUCCESS = "[User] Load Workbench Permission Success",
  LOAD_WORKBENCH_PERMISSION_FOR_ROLE_FAILED = "[User] Load Workbench Permission Failed",

  LOAD_FEATURE_PERMISSIONS = "[User] Load Feature Permissions",
  LOAD_FEATURE_PERMISSIONS_SUCCESS = "[User] Load Feature Permissions Success",
  LOAD_FEATURE_PERMISSIONS_FAILED = "[User] Load Feature Permissions Failed",

  LOAD_ACCESS_FEATURE_PERMISSIONS_FOR_ROLE = "[User] Load Access Feature Permissions For Role",
  LOAD_ACCESS_FEATURE_PERMISSIONS_FOR_ROLE_SUCCESS = "[User] Load Access Feature Permissions For Role Success",
  LOAD_ACCESS_FEATURE_PERMISSIONS_FOR_ROLE_FAILED = "[User] Load Access Feature Permissions For Role Failed",

  LOAD_USER_GROUPS = "[User] Load User Groups",
  LOAD_USER_GROUPS_SUCCESS = "[User] Load User Groups Success",
  LOAD_USER_GROUPS_FAILED = "[User] Load User Groups Failed",

  LOAD_GROUP_USERS = "[User] Load Group Users",
  LOAD_GROUP_USERS_SUCCESS = "[User] Load Group Users Success",
  LOAD_GROUP_USERS_FAILED = "[User] Load Group Users Failed",
}

export class Login implements Action {
  readonly type: string = LoginActions.LOGIN;
  constructor(public payload: any) { }
}

export class LoginSuccess implements Action {
  readonly type: string = LoginActions.LOGIN_SUCCESS;
  constructor(public payload: any) { }
}

export class LoginFailed implements Action {
  readonly type: string = LoginActions.LOGIN_FAILED;
  constructor(public payload: any) { }
}

export class Logout implements Action {
  readonly type: string = LoginActions.LOGOUT;
  constructor(public payload: any) { }
}

export class LogoutSuccess implements Action {
  readonly type: string = LoginActions.LOGOUT_SUCCESS;
  constructor(public payload: any) { }
}

export class LogoutFail implements Action {
  readonly type: string = LoginActions.LOGOUT_FAIL;
  constructor(public payload: any) {
  }
}

export class RecoverPassword implements Action {
  readonly type: string = LoginActions.RECOVER_PASSWORD;
  constructor(public payload: {
    username: string; recovery_email: string, newPassword
      : string, confirm_password: string
  }) { }
}



export class RecoverPasswordSuccess implements Action {
  readonly type: string = LoginActions.RECOVER_PASSWORD_SUCCESS;
  constructor(public payload: {
    username: string;
    recovery_email: string;
    newPassword: string;
    confirm_password: string;
  }) { }
}

export class RecoverPasswordFailed implements Action {
  readonly type: string = LoginActions.RECOVER_PASSWORD_FAILED;
  constructor(public payload: {
    username: string; recovery_email: string, newPassword
      : string, confirm_password: string
  }) { }
}

// export class UpdateUnblockUsersPassword implements Action {
//   readonly type: string = LoginActions.UPDATE_UNBLOCK_USER_PASSWORD;
//   constructor(public payload: any) { }
// }

// export class UpdateUnblockUsersPasswordSuccess implements Action {
//   readonly type: string = LoginActions.UPDATE_UNBLOCK_USER_PASSWORD_SUCCESS;
//   constructor(public payload: any) { }
// }

// export class UpdateUnblockUsersPasswordFailed implements Action {
//   readonly type: string = LoginActions.UPDATE_UNBLOCK_USER_PASSWORD_FAILED;
//   constructor(public payload: any) { }
// }

export class AuthenticationCheck implements Action {
  readonly type: string = LoginActions.AUTHENTICATION_CHECK;
  constructor(public payload: any) { }
}

export class AuthenticationSuccess implements Action {
  readonly type: string = LoginActions.AUTHENTICATION_SUCCESS;
  constructor(public payload: any) { }
}

export class AuthenticationFail implements Action {
  readonly type: string = LoginActions.AUTHENTICATION_FAIL;
  constructor(public payload: any) { }
}

export class LoadUserProfile implements Action {
  readonly type: string = UserActions.LOAD_USER_PROFILE;
  constructor(public payload: number) { }
}

export class LoadUserProfileSuccess implements Action {
  readonly type: string = UserActions.LOAD_USER_PROFILE_SUCCESS;
  constructor(public payload: IUser) { }
}

export class LoadUserProfileFailed implements Action {
  readonly type: string = UserActions.LOAD_USER_PROFILE_FAILED;
  constructor(public payload: any) { }
}

export class LoadPasswordPolicy implements Action {
  readonly type: string = UserActions.LOAD_PASSWORD_POLICY;
  constructor(public payload?: any) { }
}

export class LoadPasswordPolicySuccess implements Action {
  readonly type: string = UserActions.LOAD_PASSWORD_POLICY_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadPasswordPolicyFailed implements Action {
  readonly type: string = UserActions.LOAD_PASSWORD_POLICY_FAILED;
  constructor(public payload: any) { }
}

export class ResetPassword implements Action {
  readonly type: string = UserActions.RESET_PASSWORD;
  constructor(public payload: any) { }
}

export class ResetPasswordSuccess implements Action {
  readonly type: string = UserActions.RESET_PASSWORD_SUCCESS;
  constructor(public payload: any) { }
}

export class ResetPasswordFailed implements Action {
  readonly type: string = UserActions.RESET_PASSWORD_FAILED;
  constructor(public payload: any) { }
}



export class UpdateUser implements Action {
  readonly type: string = UserActions.UPDATE_USER;
  constructor(public payload: any) { }
}

export class UpdateUserSuccess implements Action {
  readonly type: string = UserActions.UPDATE_USER_SUCCESS;
  constructor(public payload: IUser) { }
}

export class UpdateUserFailed implements Action {
  readonly type: string = UserActions.UPDATE_USER_FAILED;
  constructor(public payload: any) { }
}

export class UpdateUserDetails implements Action {
  readonly type: string = UserActions.UPDATE_USER_DETAILS;
  constructor(public payload: any) { }
}

export class UpdateUserDetailsSuccess implements Action {
  readonly type: string = UserActions.UPDATE_USER_DETAILS_SUCCESS;
  constructor(public payload: IUser) { }
}

export class UpdateUserDetailsFailed implements Action {
  readonly type: string = UserActions.UPDATE_USER_DETAILS_FAILED;
  constructor(public payload: any) { }
}

export class SetMessage implements Action {
  readonly type: string = UserActions.SET_MESSAGE;
  constructor(public payload: any) { }
}

export class SetUserPreferences implements Action {
  readonly type: string = UserActions.SET_USER_PREFERENCES;
  constructor(public payload?: any) { }
}

export class LoadPreferences implements Action {
  readonly type: string = UserActions.LOAD_PREFERENCES;
  constructor(public payload?: any) { }
}

export class LoadPreferencesSuccess implements Action {
  readonly type: string = UserActions.LOAD_PREFERENCES_SUCCESS;
  constructor(public payload: IPreference[]) { }
}

export class LoadPreferencesFailed implements Action {
  readonly type: string = UserActions.LOAD_PREFERENCES_FAILED;
  constructor(public payload: any) { }
}

export class LoadPreferenceOptions implements Action {
  readonly type: string = UserActions.LOAD_PREFERENCE_OPTIONS;
  constructor(public payload?: any) { }
}

export class LoadPreferenceOptionsSuccess implements Action {
  readonly type: string = UserActions.LOAD_PREFERENCE_OPTIONS_SUCCESS;
  constructor(public payload: IPreferenceOption[]) { }
}

export class LoadPreferenceOptionsFailed implements Action {
  readonly type: string = UserActions.LOAD_PREFERENCE_OPTIONS_FAILED;
  constructor(public payload: any) { }
}

export class LoadUserPreferences implements Action {
  readonly type: string = UserActions.LOAD_USER_PREFERENCES;
  constructor(public payload: number) { }
}

export class LoadUserPreferencesSuccess implements Action {
  readonly type: string = UserActions.LOAD_USER_PREFERENCES_SUCCESS;
  constructor(public payload: IUserPreference[]) { }
}

export class LoadUserPreferencesFailed implements Action {
  readonly type: string = UserActions.LOAD_USER_PREFERENCES_FAILED;
  constructor(public payload: any) { }
}

export class UpdatePreference implements Action {
  readonly type: string = UserActions.UPDATE_PREFERENCE;
  constructor(
    public payload: {
      user_id: number;
      preference_id: number;
      option_id?: number;
      value?: number;
    }
  ) { }
}

export class UpdatePreferenceSuccess implements Action {
  readonly type: string = UserActions.UPDATE_PREFERENCE_SUCCESS;
  constructor(public payload: any) { }
}

export class UpdatePreferenceFailed implements Action {
  readonly type: string = UserActions.UPDATE_PREFERENCE_FAILED;
  constructor(public payload: any) { }
}

export class ResetUserPreferencesToDefault implements Action {
  readonly type: string = UserActions.RESET_USER_PREFERENCES_TO_DEFAULT;
  constructor(public payload: number) { }
}

export class ResetUserPreferencesToDefaultSuccess implements Action {
  readonly type: string = UserActions.RESET_USER_PREFERENCES_TO_DEFAULT_SUCCESS;
  constructor(public payload: any) { }
}

export class ResetUserPreferencesToDefaultFailed implements Action {
  readonly type: string = UserActions.RESET_USER_PREFERENCES_TO_DEFAULT_FAILED;
  constructor(public payload: any) { }
}

export class LoadUserBaseLocations implements Action {
  readonly type: string = UserActions.LOAD_USER_BASE_LOCATIONS;
  constructor(public payload: number) { }
}

export class LoadUserBaseLocationsSuccess implements Action {
  readonly type: string = UserActions.LOAD_USER_BASE_LOCATIONS_SUCCESS;
  constructor(public payload: IUserBaseLocation[]) { }
}

export class LoadUserBaseLocationsFailed implements Action {
  readonly type: string = UserActions.LOAD_USER_BASE_LOCATIONS_FAILED;
  constructor(public payload: any) { }
}

export class LoadUserRoles implements Action {
  readonly type: string = UserActions.LOAD_USER_ROLES;
  constructor(public payload: number) { }
}

export class LoadUserRolesSuccess implements Action {
  readonly type: string = UserActions.LOAD_USER_ROLES_SUCCESS;
  constructor(public payload: IUserRole[]) { }
}

export class LoadUserRolesFailed implements Action {
  readonly type: string = UserActions.LOAD_USER_ROLES_FAILED;
  constructor(public payload: any) { }
}

export class LoadAccessPermissionsForRole implements Action {
  readonly type: string = UserActions.LOAD_ACCESS_PERMISSIONS_FOR_ROLE;
  constructor(public payload: { roleId: number; userId: number }) { }
}

export class LoadAccessPermissionsForRoleSuccess implements Action {
  readonly type: string = UserActions.LOAD_ACCESS_PERMISSIONS_FOR_ROLE_SUCCESS;
  constructor(public payload: IRolePermission[]) { }
}

export class LoadAccessPermissionsForRoleFailed implements Action {
  readonly type: string = UserActions.LOAD_ACCESS_PERMISSIONS_FOR_ROLE_FAILED;
  constructor(public payload: any) { }
}

export class LoadWorkbenchPermissionForRole implements Action {
  readonly type: string = UserActions.LOAD_WORKBENCH_PERMISSION_FOR_ROLE;
  constructor(public payload: number) { }
}

export class LoadWorkbenchPermissionForRoleSuccess implements Action {
  readonly type: string =
    UserActions.LOAD_WORKBENCH_PERMISSION_FOR_ROLE_SUCCESS;
  constructor(public payload: IWorkbenchPermission) { }
}

export class LoadWorkbenchPermissionForRoleFailed implements Action {
  readonly type: string = UserActions.LOAD_WORKBENCH_PERMISSION_FOR_ROLE_FAILED;
  constructor(public payload: any) { }
}

export class LoadFeaturePermissions implements Action {
  readonly type: string = UserActions.LOAD_FEATURE_PERMISSIONS;
  constructor(public payload?: any) { }
}

export class LoadFeaturePermissionsSuccess implements Action {
  readonly type: string = UserActions.LOAD_FEATURE_PERMISSIONS_SUCCESS;
  constructor(public payload: IFeaturePermission[]) { }
}

export class LoadFeaturePermissionsFailed implements Action {
  readonly type: string = UserActions.LOAD_FEATURE_PERMISSIONS_FAILED;
  constructor(public payload: any) { }
}

export class LoadAccessFeaturePermissionsForRole implements Action {
  readonly type: string = UserActions.LOAD_ACCESS_FEATURE_PERMISSIONS_FOR_ROLE;
  constructor(public payload: number) { }
}

export class LoadAccessFeaturePermissionsForRoleSuccess implements Action {
  readonly type: string =
    UserActions.LOAD_ACCESS_FEATURE_PERMISSIONS_FOR_ROLE_SUCCESS;
  constructor(public payload: IRoleFeaturePermission[]) { }
}

export class LoadAccessFeaturePermissionsForRoleFailed implements Action {
  readonly type: string =
    UserActions.LOAD_ACCESS_FEATURE_PERMISSIONS_FOR_ROLE_FAILED;
  constructor(public payload: any) { }
}

export class LoadUserGroups implements Action {
  readonly type: string = UserActions.LOAD_USER_GROUPS;
  constructor(public payload: number) { }
}

export class LoadUserGroupsSuccess implements Action {
  readonly type: string = UserActions.LOAD_USER_GROUPS_SUCCESS;
  constructor(public payload: IGroup[]) { }
}

export class LoadUserGroupsFailed implements Action {
  readonly type: string = UserActions.LOAD_USER_GROUPS_FAILED;
  constructor(public payload: any) { }
}

export class LoadGroupUsers implements Action {
  readonly type: string = UserActions.LOAD_GROUP_USERS;
  constructor(public payload: number) { }
}

export class LoadGroupUsersSuccess implements Action {
  readonly type: string = UserActions.LOAD_GROUP_USERS_SUCCESS;
  constructor(public payload: IUser[]) { }
}

export class LoadGroupUsersFailed implements Action {
  readonly type: string = UserActions.LOAD_GROUP_USERS_FAILED;
  constructor(public payload: any) { }
}

export type UserAction =
  | Login
  | LoginSuccess
  | LoginFailed
  | Logout
  | LogoutSuccess
  | LogoutFail
  | RecoverPassword
  | RecoverPasswordSuccess
  | RecoverPasswordFailed
  // | UpdateUnblockUsersPassword
  // | UpdateUnblockUsersPasswordSuccess
  // | UpdateUnblockUsersPasswordFailed
  | AuthenticationCheck
  | AuthenticationFail
  | AuthenticationSuccess
  | LoadUserProfile
  | LoadUserProfileSuccess
  | LoadUserProfileFailed
  | LoadPasswordPolicy
  | LoadPasswordPolicySuccess
  | LoadPasswordPolicyFailed
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFailed
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFailed
  | UpdateUserDetails
  | UpdateUserDetailsSuccess
  | UpdateUserDetailsFailed
  | SetMessage
  | SetUserPreferences
  | LoadPreferences
  | LoadPreferencesSuccess
  | LoadPreferencesFailed
  | LoadPreferenceOptions
  | LoadPreferenceOptionsSuccess
  | LoadPreferenceOptionsFailed
  | LoadUserPreferences
  | LoadUserPreferencesSuccess
  | LoadUserPreferencesFailed
  | UpdatePreference
  | UpdatePreferenceSuccess
  | UpdatePreferenceFailed
  | LoadUserBaseLocations
  | LoadUserBaseLocationsSuccess
  | LoadUserBaseLocationsFailed
  | LoadUserRoles
  | LoadUserRolesSuccess
  | LoadUserRolesFailed
  | LoadAccessPermissionsForRole
  | LoadAccessPermissionsForRoleSuccess
  | LoadAccessPermissionsForRoleFailed
  | LoadWorkbenchPermissionForRole
  | LoadWorkbenchPermissionForRoleSuccess
  | LoadWorkbenchPermissionForRoleFailed
  | LoadFeaturePermissions
  | LoadFeaturePermissionsSuccess
  | LoadFeaturePermissionsFailed
  | LoadAccessFeaturePermissionsForRole
  | LoadAccessFeaturePermissionsForRoleSuccess
  | LoadAccessFeaturePermissionsForRoleFailed
  | LoadUserGroups
  | LoadUserGroupsSuccess
  | LoadUserGroupsFailed
  | LoadGroupUsers
  | LoadGroupUsersSuccess
  | LoadGroupUsersFailed
  ;
