import { ActionReducerMap } from '@ngrx/store';
import { ApplicationState } from './app.state';

import * as userStore from './store/user';
import * as moduleStore from './store/module';
import * as entityStore from './store/entity';
import * as datalakeStore from './store/datalake';
import * as serviceHealthCheckStore from './store/service.health.check';
import * as footerStore from './store/footer';


export const reducers: ActionReducerMap<ApplicationState> = {
  user: userStore.userReducer,
  module: moduleStore.moduleReducer,
  entity: entityStore.entityReducer,
  datalake: datalakeStore.datalakeReducer,
  serviceHealthCheck: serviceHealthCheckStore.serviceHealthCheckReducer,
  footer: footerStore.footerReducer,
};
