import { createSelector } from "@ngrx/store";
import {
  IAuthenticatedUser,
  IUser,
  IUserBaseLocation,
  IPreference,
  IPreferenceOption,
  IUserPreference,
  IPasswordPolicy,
  IUserRole,
  IRolePermission,
  IGroup,
  IWorkbenchPermission,
  IFeaturePermission,
  IRoleFeaturePermission
} from "../../core/models/user";

import { ApplicationState } from "src/app/app.state";

export interface Userstate {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  authenticatedUser: IAuthenticatedUser;
  profile: IUser;
  passwordPolicy: IPasswordPolicy;
  preferences: IPreference[];
  preferenceOptions: IPreferenceOption[];
  userPreferences: IUserPreference[];
  baseLocations: IUserBaseLocation[];
  roles: IUserRole[];
  accessRoleAndPermissions: IRolePermission[];
  accessWorkbenchPermission: IWorkbenchPermission;
  featurePermissions: IFeaturePermission[];
  accessFeaturePermissions: IRoleFeaturePermission[];
  groups: IGroup[];
  error: any;
  message: any;
  isUserProcessing: boolean;
  isDetailsProcessing: boolean;
}

export const initialState: Userstate = {
  isAuthenticated: false,
  isAuthenticating: false,
  authenticatedUser: null,
  profile: null,
  passwordPolicy: null,
  preferences: null,
  preferenceOptions: null,
  userPreferences: null,
  baseLocations: null,
  roles: null,
  accessRoleAndPermissions: null,
  accessWorkbenchPermission: null,
  featurePermissions: null,
  accessFeaturePermissions: null,
  groups: null,
  error: null,
  message: null,
  isUserProcessing: false,
  isDetailsProcessing: false
} as Userstate;

export const initialPasswordState: any = {
  id: null,
  old_password: "",
  new_password: "",
  confirm_password: "",
  is_primary: true
};

export const initialUserState: IUser = {
  id: null,
  user_id: null,
  username: "",
  designation: "",
  email_id_business: "",
  email_id_personal: "",
  is_service_accounts: null,
  location: "",
  location_id: null,
  name: "",
  phone_mobile: "",
  phone_office: "",
  phone_residence: "",
  primary_address: "",
  secondary_address: "",
  status: "",
  created_by: ""
};

export const getApplicationState = (state: ApplicationState) => state;
export const getUserState = (state: ApplicationState) => state.user;

export const getError = createSelector(
  getUserState,
  (state: Userstate) => state.error
);
export const getMessage = createSelector(
  getUserState,
  (state: Userstate) => state.message
);
export const userState = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.user
);
export const userProfile = createSelector(
  getUserState,
  (state: Userstate) => state.profile
);
export const preferences = createSelector(
  getUserState,
  (state: Userstate) => state.preferences
);
export const preferenceOptions = createSelector(
  getUserState,
  (state: Userstate) => state.preferenceOptions
);
export const userPreferences = createSelector(
  getUserState,
  (state: Userstate) => state.userPreferences
);
export const userBaseLocations = createSelector(
  getUserState,
  (state: Userstate) => state.baseLocations
);
export const userRoles = createSelector(
  getUserState,
  (state: Userstate) => state.roles
);
export const accessRoleAndPermissions = createSelector(
  getUserState,
  (state: Userstate) => state.accessRoleAndPermissions
);
export const accessWorkbenchPermission = createSelector(
  getUserState,
  (state: Userstate) => state.accessWorkbenchPermission
);
export const featurePermissions = createSelector(
  getUserState,
  (state: Userstate) => state.featurePermissions
);
export const accessFeaturePermissions = createSelector(
  getUserState,
  (state: Userstate) => state.accessFeaturePermissions
);
export const groups = createSelector(
  getUserState,
  (state: Userstate) => state.groups
);
export const loginAuthenticating = createSelector(
  getUserState,
  (state: Userstate) => state.isAuthenticating
);
