import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class  FooterService {
  constructor(private http: HttpClient) { }

  public findByCopyright(): Observable<boolean> {
    return this.http.get<any>(environment.api_services.entityService.url + '/api/copyright/details'
    ).pipe(map(response => response))
  }

  public findByCopyrightNoAuth(): Observable<any> {
    return this.http.get<any>(environment.api_services.entityService.url + '/api/copyright/detailsnoauth').pipe(map(response => response))
  }
}
