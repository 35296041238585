import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getError, getMessage } from 'src/app/store/user/user.state';
import { ApplicationState } from 'src/app/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FooterService } from 'src/app/core/services/footer';
import { UserService } from 'src/app/core/services/user';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-reset-new-password-form',
  templateUrl: './reset-new-password-form.component.html',
  styleUrls: ['./reset-new-password-form.component.css']
})
export class ResetNewPasswordFormComponent implements OnInit {
  errorData$: Observable<any>;
  message$: Observable<any>;
  username: string = "";
  copyright$: any = {};
  email: string = "";
  showPassword: string = 'password';
  showPasswordConfirmPass: string = 'password';
  successMessage: string = "";
  issuccessMessage: boolean = false;
  error: string = ""
  id: number = 0;
  forgotNewPasswordForm: FormGroup;
  formBuilder: FormBuilder = new FormBuilder();
  constructor(private store: Store<ApplicationState>, private route: ActivatedRoute,
    private footerService: FooterService, private router: Router, private userservce: UserService) { }

  ngOnInit() {
    this.errorData$ = this.store.select(getError);
    this.message$ = this.store.select(getMessage);
    this.route.queryParams.subscribe(params => {
      this.username = params.username
      this.email = params.email
      this.id = params.id
    });
    this.initialiseNewPasswordForm();

  }


  initialiseNewPasswordForm = (): void => {
    this.forgotNewPasswordForm = this.formBuilder.group({
      username: [{ value: "", disabled: false }],
      new_password: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^(?=.*[a-z]).+$/)]],
      confirmpassword: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z]).+$/)]],
    }, { validators: [this.passwordMatchValidator.bind(this)] });

  }

  get confirmpassword() {
    return this.forgotNewPasswordForm.get('confirmpassword');
  }
  get new_password() {
    return this.forgotNewPasswordForm.get('new_password');
  }
  togglePasswordVisibility = () => {

    this.showPassword = this.showPassword === 'password' ? 'text' : 'password';

  }
  togglePasswordVisibilityConfirmPass() {
    this.showPasswordConfirmPass = this.showPasswordConfirmPass === 'password' ? 'text' : 'password';

  }
  resetNewPassword = () => {
    if (this.username !== undefined) {
      this.forgotNewPasswordForm.get('username').setValue(this.username);
      this.forgotNewPasswordForm.addControl('recovery_email', new FormControl(this.email));
      this.forgotNewPasswordForm.addControl('id', new FormControl(this.id));
      this.forgotNewPasswordForm.addControl('is_primary', new FormControl(1));


      //this.store.dispatch(new UpdateUnblockPassword(this.forgotNewPasswordForm.value));
      // setTimeout(() => {
      //    this.router.navigate([''])
      // }, 3000);

      this.userservce.UpdatePasswordOfUnblockUser(this.forgotNewPasswordForm.value).subscribe((data) => {
        if (data.status == 200) {
          this.issuccessMessage = true;
          this.successMessage = data.message;
          this.error = ""
          setTimeout(() => {
            this.router.navigate([''])
          }, 3000)
        }
      })

    }

  }

  findByCopyright() {
    this.footerService.findByCopyrightNoAuth()
      .subscribe(data => {
        if (data) {
          this.copyright$ = data.filter(x => x.module_id == environment.module_id)[0];
        }
      }, (error) => {
        console.log('error', error);
      })
  };

  passwordMatchValidator(formGroup: FormGroup): { [key: string]: boolean } | null {
    const newPasswordControl = formGroup.get('new_password');
    const confirmPasswordControl = formGroup.get('confirmpassword');

    if (!newPasswordControl || !confirmPasswordControl) {
      return null; // or handle the error appropriately
    }

    const newPassword = newPasswordControl.value;
    const confirmPassword = confirmPasswordControl.value;

    if (newPassword !== confirmPassword) {
      confirmPasswordControl.setErrors({ 'passwordMismatch': true });
      return { 'passwordMismatch': true };
    } else {
      confirmPasswordControl.setErrors(null);
      return null;
    }
  }
}
