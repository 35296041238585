import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ApplicationState } from 'src/app/app.state';
import { getError, getMessage } from 'src/app/store/user/user.state';
import { FooterService } from 'src/app/core/services/footer';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.css']
})
export class LoginContainerComponent implements OnInit {
 
  errorData$: Observable<any>;
  message$: Observable<any>;
  showRestPasswordForm: boolean;
  copyright$: any = {};
  showmessage: boolean = true;

  constructor(private footerService: FooterService, private store: Store<ApplicationState>,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.findByCopyright();
    this.showmessage = true;
    this.errorData$ = this.store.select(getError);
    this.message$ = this.store.select(getMessage);
    this.route.queryParams.subscribe(params => {
      if (params.username) {
        // Query parameter 'username' is present
       const nameObject={
        username :params.username,
        email:params.email,
        id: params.id
       }
        this.router.navigate(['reset-password'], {
          queryParams: nameObject
        });
      }
    });

  }


  NaviagateToForgetPassForm() {
    this.router.navigate(['/forget-password']);
    this.showRestPasswordForm = true

  }
  findByCopyright() {
    this.footerService.findByCopyrightNoAuth()
      .subscribe(data => {
        if (data) {
          this.copyright$ = data.filter(x => x.module_id == environment.module_id)[0];
        }
      }, (error) => {
        console.log('error', error);
      })
  };
}
