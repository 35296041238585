// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  module_id: 13,
  api_services: {
    authService: {
      name: "user",
      url: "https://development.api.retailcalculus.com/auth-service"
    },
    entityService: {
      url: "https://development.api.retailcalculus.com/entity-service"
    },
    datalakeService: {
      name: "datalake",
      url: "https://development.api.retailcalculus.com/datalake-service"
    },
    itemRetailService: {
      name: "item retail",
      url: "https://development.api.retailcalculus.com/item-service"
    },
    jobService: {
      name: "jobs",
      url: "https://development.api.retailcalculus.com/jobs-service"
    },
    locationService: {
      name: "location",
      url: "https://development.api.retailcalculus.com/location-service"
    },
    mtoService: {
      name: "mto",
      url: "https://development.api.retailcalculus.com/mto-service"
    },
    uddService: {
      name: "udd",
      url: "https://development.api.retailcalculus.com/udd-service"
    },
    vendorService: {
      name: "vendor",
      url: "https://development.api.retailcalculus.com/vendor-service"
    },
    cloudCartService: {
      name: "cloud cart",
      url: "https://development.api.retailcalculus.com/cloudcart-service"
    },
    taxService: {
      name: "tax",
      url: "https://development.api.retailcalculus.com/tax-service"
    },
    deliveryBookingService: {
      name: "deliver booking",
      url: "https://development.api.retailcalculus.com/delivery-booking-service"
    },
    orderAdvisorService: {
      name: "order advisor",
      url: "https://development.api.retailcalculus.com/orderadviser-service"
  }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
