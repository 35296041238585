import { Action } from '@ngrx/store';

export enum ModuleActions {
  LOAD_MODULES = '[Module] Load Modules',
  LOAD_MODULES_SUCCESS = '[Module] Load Modules Success',
  LOAD_MODULES_FAILED = '[Module] Load Modules Failed'
}

export class LoadModules implements Action {
  readonly type: string = ModuleActions.LOAD_MODULES;

  constructor(public payload: any) {
  }
}

export class LoadModulesSuccess implements Action {
  readonly type: string = ModuleActions.LOAD_MODULES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadModulesFailed implements Action {
  readonly type: string = ModuleActions.LOAD_MODULES_FAILED;

  constructor(public payload: any) {
  }
}

export type ModuleAction = LoadModules
  | LoadModulesSuccess
  | LoadModulesFailed;
