import { Action } from "@ngrx/store";

import { IDocument } from "src/app/core/models/datalake";

export enum DatalakeActions {
  LOAD_PROFILE_LAKE_DETAILS = "[Datalake] Load Preofile Lake Details",
  LOAD_PROFILE_LAKE_DETAILS_SUCCESS = "[Datalake] Load Preofile Lake Details Success",
  LOAD_PROFILE_LAKE_DETAILS_FAILED = "[Datalake] Load Preofile Lake Details Failed",

  LOAD_USER_DIRECTORY_BY_LAKE = "[Datalake] Load User Directory By Lake",
  LOAD_USER_DIRECTORY_BY_LAKE_SUCCESS = "[Datalake] Load User Directory By Lake Success",
  LOAD_USER_DIRECTORY_BY_LAKE_FAILED = "[Datalake] Load User Directory By Lake Failed",

  LOAD_USER_DOCUMENTS_BY_LAKE_AND_USER_DIRECTORY = "[Datalake] Load User Documents By Lake And User Directory",
  LOAD_USER_DOCUMENTS_BY_LAKE_AND_USER_DIRECTORY_SUCCESS = "[Datalake] Load User Documents By Lake And User Directory Success",
  LOAD_USER_DOCUMENTS_BY_LAKE_AND_USER_DIRECTORY_FAILED = "[Datalake] Load User Documents By Lake And User Directory Failed",

  SET_PROFILE_LAKE_DETAILS = "[Datalake] Set Preofile Lake Details",
  SET_USET_DERECTORY = "[Datalake] Set User Directory",
  SET_USET_DOCUMENTS = "[Datalake] Set User Documents",

  UPLOAD_DOCUMENTS = "[Datalake] Upload Documents",
  // UPLOAD_DOCUMENTS_SUCCESS = "[Datalake] Upload Documents Success",
  // UPLOAD_DOCUMENTS_FAILED = "[Datalake] Upload Documents Failed",
  UPLOAD_DOCUMENT = "[Datalake] Upload Document",
  UPLOAD_DOCUMENT_SUCCESS = "[Datalake] Upload Document Success",
  UPLOAD_DOCUMENT_FAILED = "[Datalake] Upload Document Failed",

  DOWNLOAD_DOCUMENT = "[Datalake] Download Document",
  DOWNLOAD_DOCUMENT_SUCCESS = "[Datalake] Download Document Success",
  DOWNLOAD_DOCUMENT_FAILED = "[Datalake] Download Document Failed",

  REMOVE_DOCUMENT = "[Datalake] Remove Document",
  REMOVE_DOCUMENT_SUCCESS = "[Datalake] Remove Document Success",
  REMOVE_DOCUMENT_FAILED = "[Datalake] Remove Document Failed",

  LOAD_LAST_DB_BACKUP_DATE = "[Datalake] Load Last DB Backup",
  LOAD_LAST_DB_BACKUP_DATE_SUCCESS = "[Datalake] Load Last DB Backup Success",
  LOAD_LAST_DB_BACKUP_DATE_FAILED = "[Datalake] Load Last DB Backup Failed"
}

export class LoadProfileLakeDetails implements Action {
  readonly type: string = DatalakeActions.LOAD_PROFILE_LAKE_DETAILS;
  constructor(public payload?: any) {}
}

export class LoadProfileLakeDetailsSuccess implements Action {
  readonly type: string = DatalakeActions.LOAD_PROFILE_LAKE_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadProfileLakeDetailsFailed implements Action {
  readonly type: string = DatalakeActions.LOAD_PROFILE_LAKE_DETAILS_FAILED;
  constructor(public payload: any) {}
}

export class LoadUserDirectoryByLake implements Action {
  readonly type: string = DatalakeActions.LOAD_USER_DIRECTORY_BY_LAKE;
  constructor(public payload: number) {}
}

export class LoadUserDirectoryByLakeSuccess implements Action {
  readonly type: string = DatalakeActions.LOAD_USER_DIRECTORY_BY_LAKE_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadUserDirectoryByLakeFailed implements Action {
  readonly type: string = DatalakeActions.LOAD_USER_DIRECTORY_BY_LAKE_FAILED;
  constructor(public payload: any) {}
}

export class LoadUserDocumentsByLakeAndUserDirectory implements Action {
  readonly type: string =
    DatalakeActions.LOAD_USER_DOCUMENTS_BY_LAKE_AND_USER_DIRECTORY;
  constructor(public payload: { lakeId: number; parentId: number }) {}
}

export class LoadUserDocumentsByLakeAndUserDirectorySuccess implements Action {
  readonly type: string =
    DatalakeActions.LOAD_USER_DOCUMENTS_BY_LAKE_AND_USER_DIRECTORY_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadUserDocumentsByLakeAndUserDirectoryFailed implements Action {
  readonly type: string =
    DatalakeActions.LOAD_USER_DOCUMENTS_BY_LAKE_AND_USER_DIRECTORY_FAILED;
  constructor(public payload: any) {}
}

export class SetProfileLakeDetails implements Action {
  readonly type: string = DatalakeActions.SET_PROFILE_LAKE_DETAILS;
  constructor(public payload: any) {}
}

export class SetDirectory implements Action {
  readonly type: string = DatalakeActions.SET_USET_DERECTORY;
  constructor(public payload: any) {}
}

export class SetDocuments implements Action {
  readonly type: string = DatalakeActions.SET_USET_DOCUMENTS;
  constructor(public payload: any) {}
}

export class UploadDocuments implements Action {
  readonly type: string = DatalakeActions.UPLOAD_DOCUMENTS;
  constructor(public payload: any) {}
}

export class UploadDocument implements Action {
  readonly type: string = DatalakeActions.UPLOAD_DOCUMENT;
  constructor(public payload: any) {}
}

export class UploadDocumentSuccess implements Action {
  readonly type: string = DatalakeActions.UPLOAD_DOCUMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class UploadDocumentFailed implements Action {
  readonly type: string = DatalakeActions.UPLOAD_DOCUMENT_FAILED;
  constructor(public payload: any) {}
}

export class DownloadDocument implements Action {
  readonly type: string = DatalakeActions.DOWNLOAD_DOCUMENT;
  constructor(public payload: { document: IDocument; query?: any }) {}
}

export class DownloadDocumentSuccess implements Action {
  readonly type: string = DatalakeActions.DOWNLOAD_DOCUMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class DownloadDocumentFailed implements Action {
  readonly type: string = DatalakeActions.DOWNLOAD_DOCUMENT_FAILED;
  constructor(public payload: any) {}
}

export class RemoveDocument implements Action {
  readonly type: string = DatalakeActions.REMOVE_DOCUMENT;
  constructor(public payload: { document: IDocument; query?: any }) {}
}

export class RemoveDocumentSuccess implements Action {
  readonly type: string = DatalakeActions.REMOVE_DOCUMENT_SUCCESS;
  constructor(public payload: number) {}
}

export class RemoveDocumentFailed implements Action {
  readonly type: string = DatalakeActions.REMOVE_DOCUMENT_FAILED;
  constructor(public payload: any) {}
}

export class LoadLastDBBackup implements Action {
  readonly type: string = DatalakeActions.LOAD_LAST_DB_BACKUP_DATE;
  constructor(public payload: { document: IDocument; query?: any }) {}
}

export class LoadLastDBBackupSuccess implements Action {
  readonly type: string = DatalakeActions.LOAD_LAST_DB_BACKUP_DATE_SUCCESS;
  constructor(public payload: number) {}
}

export class LoadLastDBBackupFailed implements Action {
  readonly type: string = DatalakeActions.LOAD_LAST_DB_BACKUP_DATE_FAILED;
  constructor(public payload: any) {}
}

export type DatalakeAction =
  | LoadProfileLakeDetails
  | LoadProfileLakeDetailsSuccess
  | LoadProfileLakeDetailsFailed
  | LoadUserDirectoryByLake
  | LoadUserDirectoryByLakeSuccess
  | LoadUserDirectoryByLakeFailed
  | SetProfileLakeDetails
  | SetDirectory
  | SetDocuments
  | UploadDocuments
  | UploadDocument
  | UploadDocumentSuccess
  | UploadDocumentFailed
  | DownloadDocument
  | DownloadDocumentSuccess
  | DownloadDocumentFailed
  | RemoveDocument
  | RemoveDocumentSuccess
  | RemoveDocumentFailed
  | LoadLastDBBackup
  | LoadLastDBBackupSuccess
  | LoadLastDBBackupFailed
  ;
