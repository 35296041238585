import * as serviceHealthCheckActions from "./service.health.check.action";

export function serviceHealthCheckReducer(state: any, action: serviceHealthCheckActions.ServiceHealthCheckAction): any {
  switch (action.type) {
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_ENTITY_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Entity Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "Entity Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_ENTITY_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Entity Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "Entity Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_ITEM_RETAIL_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Item Retail Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "Item Retail Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_ITEM_RETAIL_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Item Retail Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "Item Retail Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_JOB_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Job Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "Job Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_JOB_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Job Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "Job Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_LOCATION_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Location Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "Location Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_LOCATION_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Location Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "Location Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_MTO_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "MTO Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "MTO Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_MTO_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "MTO Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "MTO Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_UDD_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "User Defined Data Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "User Defined Data Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_UDD_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "User Defined Data Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "User Defined Data Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_USER_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "User Management";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "User Management");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_USER_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "User Management";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "User Management");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_VENDOR_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Vendor Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "Vendor Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_VENDOR_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 1;
      action.payload.moduleName = "Prime Services";
      action.payload.serviceName = "Vendor Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "Vendor Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_CLOUD_CART_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 2;
      action.payload.moduleName = "Product Explorer Services";
      action.payload.serviceName = "Cloud Cart Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "Cloud Cart Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_CLOUD_CART_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 2;
      action.payload.moduleName = "Product Explorer Services";
      action.payload.serviceName = "Cloud Cart Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "Cloud Cart Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_TAX_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 2;
      action.payload.moduleName = "Product Explorer Services";
      action.payload.serviceName = "Tax Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "Tax Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_TAX_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 2;
      action.payload.moduleName = "Product Explorer Services";
      action.payload.serviceName = "Tax Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "Tax Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_DELIVERY_BOOKING_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 2;
      action.payload.moduleName = "Product Explorer Services";
      action.payload.serviceName = "Delivery Booking Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "Delivery Booking Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_DELIVERY_BOOKING_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 2;
      action.payload.moduleName = "Product Explorer Services";
      action.payload.serviceName = "Delivery Booking Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "Delivery Booking Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_ORDER_ADVISOR_SERVICE_HEALTH_CHECK_SUCCESS: {
      action.payload.sequence = 2;
      action.payload.moduleName = "Product Explorer Services";
      action.payload.serviceName = "Order Advisor Service";
      action.payload.up = true;
      action.payload.down = false;
      let index = state.services.findIndex((service: any) => service.serviceName === "Order Advisor Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    case serviceHealthCheckActions.ServiceHealthCheckActions.LOAD_ORDER_ADVISOR_SERVICE_HEALTH_CHECK_FAILED: {
      action.payload.sequence = 2;
      action.payload.moduleName = "Product Explorer Services";
      action.payload.serviceName = "Order Advisor Service";
      action.payload.up = false;
      action.payload.down = true;
      let index = state.services.findIndex((service: any) => service.serviceName === "Order Advisor Service");
      if (index >= 0) {
        state.services[index] = action.payload;
      } else {
        state.services.push(action.payload);
      }
      return {
        ...state
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}
