import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let error = err;
      if (err.status === 401) {
        // redirect to login page if 401 response returned from api
        this.router.navigate(['/login']);
      }
      if (err && err.statusText) {
        error = err.statusText;
      }
      if (err && err.error) {
        error = err.error;
      }
      if (err && err.error && err.error.message) {
        error = err.error.message;
      }
      if (err && err.error && err.error.error) {
        error = err.error.error;
      }
      if (err && err.error && err.error && err.error.error && err.error.error.details) {
        error = err.error.error.details
      }
      if (err && err.error && err.error.error && err.error.error.error) {
        error = err.error.error.error;
      }
      return throwError(error);
    }));
  }
}
