import { Action } from "@ngrx/store";

export enum ServiceHealthCheckActions {
  LOAD_ENTITY_SERVICE_HEALTH_CHECK = "[Service Health Check] Load Entity Service Status",
  LOAD_ENTITY_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load Entity Service Status Success",
  LOAD_ENTITY_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load Entity Service Status Failed",

  LOAD_ITEM_RETAIL_SERVICE_HEALTH_CHECK = "[Service Health Check] Load Item Retail Service Health Check",
  LOAD_ITEM_RETAIL_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load Item Retail Service Health Check Success",
  LOAD_ITEM_RETAIL_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load Item Retail Service Health Check Failed",

  LOAD_JOB_SERVICE_HEALTH_CHECK = "[Service Health Check] Load Job Service Status",
  LOAD_JOB_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load Job Service Status Success",
  LOAD_JOB_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load Job Service Status Failed",

  LOAD_LOCATION_SERVICE_HEALTH_CHECK = "[Service Health Check] Load Location Service Status",
  LOAD_LOCATION_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load Location Service Status Success",
  LOAD_LOCATION_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load Location Service Status Failed",

  LOAD_MTO_SERVICE_HEALTH_CHECK = "[Service Health Check] Load MTO Service Status",
  LOAD_MTO_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load MTO Service Status Success",
  LOAD_MTO_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load MTO Service Status Failed",

  LOAD_UDD_SERVICE_HEALTH_CHECK = "[Service Health Check] Load UDD Service Status",
  LOAD_UDD_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load UDD Service Status Success",
  LOAD_UDD_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load UDD Service Status Failed",

  LOAD_USER_SERVICE_HEALTH_CHECK = "[Service Health Check] Load User Service Status",
  LOAD_USER_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load User Service Status Success",
  LOAD_USER_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load User Service Status Failed",

  LOAD_VENDOR_SERVICE_HEALTH_CHECK = "[Service Health Check] Load Vendor Service Health Check",
  LOAD_VENDOR_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load Vendor Service Health Check Success",
  LOAD_VENDOR_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load Vendor Service Health Check Failed",

  LOAD_CLOUD_CART_SERVICE_HEALTH_CHECK = "[Service Health Check] Load Cloud Cart Service Status",
  LOAD_CLOUD_CART_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load Cloud Cart Service Status Success",
  LOAD_CLOUD_CART_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load Cloud Cart Service Status Failed",

  LOAD_TAX_SERVICE_HEALTH_CHECK = "[Service Health Check] Load Tax Service Status",
  LOAD_TAX_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load Tax Service Status Success",
  LOAD_TAX_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load Tax Service Status Failed",

  LOAD_DELIVERY_BOOKING_SERVICE_HEALTH_CHECK = "[Service Health Check] Load Delivery Booking Service Health Check",
  LOAD_DELIVERY_BOOKING_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load Delivery Booking Service Health Check Success",
  LOAD_DELIVERY_BOOKING_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load Delivery Booking Service Health Check Failed",

  LOAD_ORDER_ADVISOR_SERVICE_HEALTH_CHECK = "[Service Health Check] Load Order Advisor Service Health Check",
  LOAD_ORDER_ADVISOR_SERVICE_HEALTH_CHECK_SUCCESS = "[Service Health Check] Load Order Advisor Service Health Check Success",
  LOAD_ORDER_ADVISOR_SERVICE_HEALTH_CHECK_FAILED = "[Service Health Check] Load Order Advisor Service Health Check Failed"
}

export class LoadEntityServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_ENTITY_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadEntityServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_ENTITY_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadEntityServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_ENTITY_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadItemRetailServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_ITEM_RETAIL_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadItemRetailServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_ITEM_RETAIL_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadItemRetailServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_ITEM_RETAIL_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadJobServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_JOB_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadJobServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_JOB_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadJobServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_JOB_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadLocationServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_LOCATION_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadLocationServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_LOCATION_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadLocationServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_LOCATION_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadMTOServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_MTO_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadMTOServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_MTO_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadMTOServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_MTO_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadUDDServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_UDD_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadUDDServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_UDD_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadUDDServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_UDD_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadUserServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_USER_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadUserServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_USER_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadUserServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_USER_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadVendorServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_VENDOR_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadVendorServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_VENDOR_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadVendorServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_VENDOR_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadCloudCartServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_CLOUD_CART_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadCloudCartServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_CLOUD_CART_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadCloudCartServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_CLOUD_CART_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadTaxServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_TAX_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadTaxServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_TAX_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadTaxServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_TAX_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadDeliveryBookingServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_DELIVERY_BOOKING_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadDeliveryBookingServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_DELIVERY_BOOKING_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadDeliveryBookingServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_DELIVERY_BOOKING_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export class LoadOrderAdvisorServiceHealthCheck implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_ORDER_ADVISOR_SERVICE_HEALTH_CHECK;
  constructor(public payload: any) { }
}

export class LoadOrderAdvisorServiceHealthCheckSuccess implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_ORDER_ADVISOR_SERVICE_HEALTH_CHECK_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadOrderAdvisorServiceHealthCheckFailed implements Action {
  readonly type: string = ServiceHealthCheckActions.LOAD_ORDER_ADVISOR_SERVICE_HEALTH_CHECK_FAILED;
  constructor(public payload: any) { }
}

export type ServiceHealthCheckAction =
  | LoadEntityServiceHealthCheck
  | LoadEntityServiceHealthCheckSuccess
  | LoadEntityServiceHealthCheckFailed
  | LoadItemRetailServiceHealthCheck
  | LoadItemRetailServiceHealthCheckSuccess
  | LoadItemRetailServiceHealthCheckFailed
  | LoadJobServiceHealthCheck
  | LoadJobServiceHealthCheckSuccess
  | LoadJobServiceHealthCheckFailed
  | LoadLocationServiceHealthCheck
  | LoadLocationServiceHealthCheckSuccess
  | LoadLocationServiceHealthCheckFailed
  | LoadMTOServiceHealthCheck
  | LoadMTOServiceHealthCheckSuccess
  | LoadMTOServiceHealthCheckFailed
  | LoadUDDServiceHealthCheck
  | LoadUDDServiceHealthCheckSuccess
  | LoadUDDServiceHealthCheckFailed
  | LoadUserServiceHealthCheck
  | LoadUserServiceHealthCheckSuccess
  | LoadUserServiceHealthCheckFailed
  | LoadVendorServiceHealthCheck
  | LoadVendorServiceHealthCheckSuccess
  | LoadVendorServiceHealthCheckFailed
  | LoadCloudCartServiceHealthCheck
  | LoadCloudCartServiceHealthCheckSuccess
  | LoadCloudCartServiceHealthCheckFailed
  | LoadTaxServiceHealthCheck
  | LoadTaxServiceHealthCheckSuccess
  | LoadTaxServiceHealthCheckFailed
  | LoadDeliveryBookingServiceHealthCheck
  | LoadDeliveryBookingServiceHealthCheckSuccess
  | LoadDeliveryBookingServiceHealthCheckFailed
  | LoadOrderAdvisorServiceHealthCheck
  | LoadOrderAdvisorServiceHealthCheckSuccess
  | LoadOrderAdvisorServiceHealthCheckFailed
  ;
