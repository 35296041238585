import * as  footerActions from './footer.action';

export function footerReducer(state: any, action: footerActions.FooterAction): any {
  switch (action.type) {

    case  footerActions. FooterActions.LOAD_VALUE_COPYRIGHT_SUCCESS: {
      return {
        ...state,
        copyright: action.payload[0]
      }
    }

    case  footerActions. FooterActions.LOAD_VALUE_COPYRIGHT_FAILURE: {
      return {
        ...state,
        copyright: []
      }
    }

    default: {
      return {
        ...state
      };
    }
  }
}
