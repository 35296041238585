import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, catchError, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as  FooterActions from './footer.action';
import {  FooterService } from '../../core/services/footer';
import { ApplicationState } from 'src/app/app.state';

@Injectable()
export class  FooterEffects {
  constructor(
    private actions$: Actions,
    private  footerService:  FooterService,
    public store: Store<ApplicationState>
  ) { }

  @Effect()
  public CopyrightEffect = this.actions$.pipe(
    ofType( FooterActions. FooterActions.LOAD_VALUE_COPYRIGHT),
    flatMap((Actions:  FooterActions.Copyright) =>
      this. footerService.findByCopyright().pipe(
        map(response => (new  FooterActions.CopyrightSuccess(response)))
      )
    )
  );

}