import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import * as userStore from './store/user';
import * as moduleStore from './store/module';
import * as entityStore from './store/entity';
import * as datalakeStore from './store/datalake';
import * as serviceHealthCheckStore from './store/service.health.check';
import * as footerStore from './store/footer';



@Injectable()
export class AppEffects {
  constructor(private actions: Actions) { }
}

export const effects: Array<any> = [
  userStore.UserEffects,
  moduleStore.ModuleEffects,
  entityStore.EntityEffects,
  datalakeStore.DatalakeEffects,
  serviceHealthCheckStore.ServiceHealthCheckEffects,
  footerStore.FooterEffects
];
