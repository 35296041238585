import { Action } from '@ngrx/store';


export enum  FooterActions {
    LOAD_VALUE_COPYRIGHT = '[ Footer] Load Copyright',
    LOAD_VALUE_COPYRIGHT_SUCCESS = '[ Footer] Load Copyright Success',
    LOAD_VALUE_COPYRIGHT_FAILURE = '[ Footer] Load Copyright Failure'
}


export class Copyright implements Action {
    readonly type: string =  FooterActions.LOAD_VALUE_COPYRIGHT;
    constructor(public payload: any) { }
}

export class CopyrightSuccess implements Action {
    readonly type: string =  FooterActions.LOAD_VALUE_COPYRIGHT_SUCCESS;
    constructor(public payload: any) { }
}

export class CopyrightFailure implements Action {
    readonly type: string =  FooterActions.LOAD_VALUE_COPYRIGHT_FAILURE;
    constructor(public payload: any) { }
}

export type  FooterAction =
    | Copyright
    | CopyrightSuccess
    | CopyrightFailure
    ;
