import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, catchError, withLatestFrom, filter, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as entityActions from './entity.action';
import { EntityService } from './../../core/services/entity';
import { ApplicationState } from 'src/app/app.state';
import { entities } from './entity.state';

@Injectable()
export class EntityEffects {
  constructor(
    private actions$: Actions,
    private entityService: EntityService,
    public store: Store<ApplicationState>
  ) { }

  @Effect()
  public loadEntitiesEffect = this.actions$.pipe(
    ofType(entityActions.EntityActions.LOAD_ENTITIES),
    withLatestFrom(this.store.pipe(select(entities))),
    filter(([_, entities]) => { if (entities) { return false } return true }),
    exhaustMap(() =>
      this.entityService.getEntities().pipe(
        map(response => (new entityActions.LoadEntitiesSuccess(response))),
        catchError((error) => of(
          new entityActions.LoadEntitiesFailed(error)
        ))
      )
    )
  );
}

