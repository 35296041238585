import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { getError, getMessage } from 'src/app/store/user/user.state';
import { Login } from "src/app/store/user";
import { ApplicationState } from "src/app/app.state";
import { loginAuthenticating } from "src/app/store/user/user.state";
import { Observable } from "rxjs";


@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.css"]
})
export class LoginFormComponent implements OnInit {
  loginForm: FormGroup;
  @Output() Mainlogin = new EventEmitter();
  isAuthenticating$: Observable<boolean>;
  errorData$: Observable<any>;
  message$: Observable<any>
  showPassword: string = 'password';
  hide_show_password = false;
  hide_confirm_pass = false;
  confirm_password_input_type = "password";
  password_input_type = "password";
  constructor(private store: Store<ApplicationState>) { }

  ngOnInit() {
    this.errorData$ = this.store.select(getError);
    this.message$ = this.store.select(getMessage);
    this.loginForm = new FormGroup({
      username: new FormControl("", [Validators.required]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern(/^(?=.*[a-z]).+$/)
      ])
    });
  }

  get username() {
    return this.loginForm.get("username");
  }
  get password() {
    return this.loginForm.get("password");
  }
  togglePasswordVisibility() {
    this.showPassword = this.showPassword === 'password' ? 'text' : 'password';

  }
  login = (): void => {
    this.isAuthenticating$ = this.store.select(loginAuthenticating);
    const loginPayload = {
      username: this.username.value,
      password: this.password.value
    };
    this.store.dispatch(new Login(loginPayload));
  };
}
