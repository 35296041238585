import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { IModule } from '../models/module';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  constructor(private http: HttpClient) { }

  /* get package modules */
  public getModules = (): Observable<IModule[]> => {
    return this.http.get<any>(`${environment.api_services.authService.url}/api/access/modules`).pipe(
      map((response: any) => response.data),
      catchError(this.handleError)
    );
  };

  handleError(error) {
    return throwError(error);
  }

}
