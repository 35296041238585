import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginContainerComponent } from './login-container/login-container.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { ResetNewPasswordFormComponent } from './reset-new-password-form/reset-new-password-form.component';

const routes: Routes = [
  {
    path: '',
    component: LoginContainerComponent
  },
  {
    path: 'forget-password',
    component: ForgotPasswordFormComponent
  },
  {
    path: 'reset-password',
    component: ResetNewPasswordFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
