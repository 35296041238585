import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { ILake, IDocument } from '../models/datalake';

interface IObj {
  field?: any
}

@Injectable({
  providedIn: 'root'
})
export class DatalakeService {
  constructor(private http: HttpClient) { }

  public getProfileLake(): Observable<ILake> {
    return this.http.get<any>(`${environment.api_services.datalakeService.url}/api/lake/Profile`).pipe(
      map((response: any) => {
        if (response && response.data && response.data[0]) { return response.data[0]; } else { return {}; }
      }),
      catchError(this.handleError)
    )
  }

  public getUserDirectoryByLake(lakeId: number): Observable<IDocument> {
    return this.http.get<any>(`${environment.api_services.datalakeService.url}/api/lake/drop/lake/${lakeId}/userdirectory`).pipe(
      map((response: any) => {
        if (response && response.data) { return response.data; } else { return {}; }
      }),
      catchError(this.handleError)
    )
  }

  public getCorporateConfigList(): Observable<any> {
    return this.http.get(`${environment.api_services.itemRetailService.url}/api/banner/corporate_marketing`).pipe(
      map((response: any) => {
        if (response && response.data) { return response.data; } else { return {}; }
      }),
      catchError(this.handleError)
    )
  }

  public prepareDownloadUrl(url, uuid) {
    let token = sessionStorage.getItem("token");
    let session = sessionStorage.getItem("session-id");
    return (
      `${environment.api_services.datalakeService.url}/api/lake/corporatedrop/download/` +
      url +
      "?uuid= " +
      uuid +
      "&token=" +
      token +
      "&session_id=" +
      session
    );
  }

  public getUserDocuments(lakeId: number, parentId: number): Observable<IDocument[]> {
    return this.http.get<any>(`${environment.api_services.datalakeService.url}/api/lake/drop/lake/${lakeId}?parent_id="${parentId}"`).pipe(
      map((response: any) => {
        if (response && response.data) { return response.data; } else { return {}; }
      }),
      catchError(this.handleError)
    )
  }

  public uploadDocument(document: any): Observable<any> {
    const dropFormData = new FormData();
    dropFormData.append('file', document.drop);
    delete document.drop;
    Object.entries(document).forEach((element) => {
      const obj: IObj = {};
      obj.field = element[1];
      if (element[1]) {
        dropFormData.append(`data[${element[0]}]`, obj.field);
      }
    });

    return this.http.post<any>(`${environment.api_services.datalakeService.url}/api/lake/drop`, dropFormData).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    )
  }

  public downloadFile(documentId: number, query: any) {
    return this.http.get(
      `${environment.api_services.datalakeService.url}/api/lake/drop/download/${documentId}`,
      { params: query, responseType: 'arraybuffer' }
    ).pipe(
      map((response: any) => response),
      catchError(this.handleError))
  }

  public removeDocument(drop: IDocument, query: any) {
    return this.http.post(`${environment.api_services.datalakeService.url}/api/lake/drop/remove`, drop, { params: query })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  };

  public getLastDayOfBackup() {
    return this.http.get(`${environment.api_services.datalakeService.url}/api/db/backup/lastbackup`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  };

  handleError(error) {
    return throwError(error);
  }

}
