import { createSelector } from "@ngrx/store";

import { ApplicationState } from "src/app/app.state";

export interface ServiceHealthCheckState {
  services: any
}

export const initialState: ServiceHealthCheckState = {
  services: []
};

export const getApplicationState = (state: ApplicationState) => state;
export const getServiceHealthCheckState = (state: ApplicationState) => state.serviceHealthCheck;

export const services = createSelector(
  getServiceHealthCheckState,
  (state: ServiceHealthCheckState) => state.services
);

export const serviceHealthCheckState = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.serviceHealthCheck
);
