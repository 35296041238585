import * as entityActions from './entity.action';

export function entityReducer(state: any, action: entityActions.EntityAction): any {
  switch (action.type) {
    case entityActions.EntityActions.LOAD_ENTITIES: {
      return {
        ...state,
      };
    }
    case entityActions.EntityActions.LOAD_ENTITIES_SUCCESS: {
      return {
        ...state,
        entities: action.payload,
        message: null
      };
    }
    case entityActions.EntityActions.LOAD_ENTITIES_FAILED: {
      return {
        ...state,
        entities: null,
        message: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}
