import { HttpRequest, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/app.state';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store<ApplicationState>, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.endsWith('/authentication/authenticate') && !req.url.endsWith('/forgotpassword') && !req.url.endsWith('/detailsnoauth')
    && !req.url.includes('/updateUnblockedpassword')) {
      req = req.clone({
        setHeaders: {
          'x-access-token': sessionStorage.getItem('token'),
          'session-id': sessionStorage.getItem('session-id')
        }
      });
    }
    return next.handle(req);

  }
}
