import { createSelector } from '@ngrx/store';

import * as userStore from './store/user';
import * as moduleStore from './store/module';
import * as entityStore from './store/entity';
import * as datalakeStore from './store/datalake';
import * as serviceHealthCheckStore from './store/service.health.check';
import * as footerStore from './store/footer';

export interface ApplicationState {
  user: userStore.Userstate;
  module: moduleStore.ModuleState;
  entity: entityStore.EntityState;
  datalake: datalakeStore.DatalakeState;
  serviceHealthCheck: serviceHealthCheckStore.ServiceHealthCheckState;
  footer: footerStore.FooterState;
}

export const initialState: ApplicationState = {
  user: userStore.initialState,
  module: moduleStore.initialState,
  entity: entityStore.initialState,
  datalake: datalakeStore.initialState,
  serviceHealthCheck: serviceHealthCheckStore.initialState,
  footer: footerStore.initialState,
};

export const effects: Array<any> = [userStore.UserEffects];

export const getApplicationState = (state: ApplicationState) => state;
export const getUserState = (state: ApplicationState) => state.user;
export const  getcopyright= (state: ApplicationState) => state.footer;

export const userState = createSelector(getApplicationState, (state: ApplicationState) => state.user);
