import { createSelector } from "@ngrx/store";

import { ApplicationState } from "src/app/app.state";
import { ILake, IDocument } from "../../core/models/datalake";

export interface DatalakeState {
  profileLake: ILake;
  userDirectory: IDocument;
  userDocuments: IDocument[];
  message: any;
  isDeleting: boolean;
  isUploading: boolean;
  dbLastBackupDate: any;
}

export const initialState: DatalakeState = {
  profileLake: null,
  userDirectory: null,
  userDocuments: null,
  message: null,
  isDeleting: false,
  isUploading: false,
  dbLastBackupDate: {}
} as DatalakeState;

export const initialDocumentFormState = {
  id: null,
  lake_id: null,
  stream_id: null,
  status_id: 1,
  uuid: null,
  type: null,
  instance_id: -1,
  drop_type_id: 1,
  drop_type: "File",
  parent_id: null,
  name: null,
  size: null,
  created_dt: null,
  updated_dt: null,
  created_by: null,
  updated_by: null
};

export const getApplicationState = (state: ApplicationState) => state;

// exports datalake from ApplicationState
export const datalake = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.datalake
);

// exports profileLake from ApplicationState.datalake
export const profileLake = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.datalake.profileLake
);

// exports userDirectory from ApplicationState.datalake
export const userDirectory = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.datalake.userDirectory
);

// exports userDocuments from ApplicationState.datalake
export const userDocuments = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.datalake.userDocuments
);

export const documentisDeleting = createSelector(
  getApplicationState,
  (state: ApplicationState) => {
    return state.datalake.isDeleting;
  }
);
export const documentUploading = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.datalake.isUploading
);
export const dbLastBackupDate = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.datalake.dbLastBackupDate
);
